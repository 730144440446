import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import {
  entryType,
  equipmentStatus,
  getSteps,
  GXP_DROP_CHECK,
  GXPemptyInstrumentsOptions,
  IR_EDIT_MANDATORY_FIELDS,
  IR_MANDATORY_FIELDS,
  MODIFY_INSTRUMENT,
  UPDATE_SAP_MANDATORY_FIELDS
} from "../../../constants";
import StepperElement from "./StepperElement";
import { useFormikContext } from "formik";
import { ConfirmDialog } from "../../../components/shared";
import useDialog from "../../../utils/hooks/useDialog";
import { compose, withApollo } from "react-apollo";
import { connect, useSelector } from "react-redux";
import { CenteredSpinner } from "@digitallab/grid-common-components";
import {
  loadBuildingData as loadBuildingDataAction,
  loadFloorData as loadFloorDataAction,
  loadInstruments as loadInstrumentsAction,
  loadReasonData as loadReasonDataAction,
  loadRoomData as loadRoomDataAction,
  updateEditInstrumentDetail as updateEditInstrumentDetailAction,
  updateInstrumentDetail as updateInstrumentDetailAction
} from "../redux/actions";
import omitDeep from "omit-deep-lodash";
import { AddContainer, AddContainerBody } from "./AddEquipmentStyle";
import {
  CREATE_DIGITAL_LAB_INSTRUMENT_REPOSITORY_ENTRY,
  LIST_IR_ENTRY_BY_MANUFACTURE_MODEL_SNO,
  UPDATE_DIGITAL_LAB_INSTRUMENT_REPOSITORY_ENTRY,
  UPDATE_EQUIPMENT_WITH_REVIEW
} from "../../../gql/landingapi";
import { emptyInstrument } from "../../importFile/utils/emptyInstrument";
import Notify from "../../notifications/Notify";
import CancelButton from "./../CancelButton";
import FetchInstrumentFromDataRiver from "../FetchInstrumentFromDataRiver";
import { cloneDeep, find, isEqual } from "lodash";
import { StepperContext } from "../Context";
import { changeDateFormat } from "../helpers";
import { getBuilding, getFloor, getReasons, getRoom } from "../LoadInstrumentsInfo";
import { AddStepContent, EditStepContent } from "./StepContentConfig";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import DATA_MODEL_TABLE from "../../../utils/constants/dataModelTable";
import { OwcButton, OwcWorkflowOverlay } from "@one/react";
import { getReviewers, isData4eye, isNewData4eye } from "../../../utils/helpers/4eye";
import { v4 as uuidv4 } from "uuid";
import AddReviewerWithoutReason from "../../../components/shared/AddReviewerWithoutReason";
import {
  checkUnicityForEquipmentOnAdd,
  checkUnicityForEquipmentOnEdit,
  getEquipmentListAndUpdateRedux
} from "./AddEditEquipmentHelperFunctions";

const AddInstrumentContainer = ({
  isEditMode,
  setIsEditMode,
  cancelStep,
  client,
  activeStep,
  setActiveStep,
  updateInstrumentDetail,
  updateEditInstrumentDetail,
  loadInstruments,
  instrumentDetail,
  editInstrumentDetail,
  selectedItem,
  setSelectedItem,
  linkedInstanceList,
  loadReasonData,
  user,
  limit,
  sites,
  activeModule
}) => {
  const { dirty, errors, isValid, values, resetForm, setValues, setErrors, setFieldTouched } = useFormikContext();

  const steps = getSteps();
  const listOptions = useSelector((state) => state.instruments);
  const { openDialog, ...dialogProps } = useDialog();
  const reviewerDialog = useDialog();
  const [tempSelectedInstrument, setTempSelectedInstrument] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isReasonUpdate, setIsReasonUpdate] = useState(false);
  const [isReviewerUpdate, setIsReviewerUpdate] = useState(false);
  const [reviewers, setReviewers] = useState([]);
  const [isFetchFromSap, setIsFetchFromSap] = useState(false);
  const [isFetchSuccess, setIsFetchSuccess] = useState(false);
  const [isUpdateBadge, setIsUpdateBadge] = useState(false);
  const [sopHelperText] = useState(true);
  const [disabledFinishButton, setDisabledFinishButton] = useState(true);
  const [disableSave, setDisableSave] = useState(true);
  const [allStepComplete, setAllStepComplete] = useState(false);
  const [isFromSap, setIsFromSap] = useState(false);
  const [is4eyeFormOpen, setIs4eyeFormOpen] = useState(false);
  const [is4eye, setIs4eye] = useState(null);
  const [originalEquipmentValues, setOriginalEquipmentValues] = useState();
  const [isLinkedInstance, setIsLinkedInstance] = useState(false);
  const { trackEvent } = useMatomo();
  const { stepsArray, setStepsArray, secondaryIdMappingFields } = useContext(StepperContext);
  const expandableGroupElement = useRef(null);

  useEffect(() => {
    if (isEditMode) {
      if (originalEquipmentValues) {
        setIs4eye(
          isData4eye(originalEquipmentValues, values)
        );
      }
    } else {
      setIs4eye(isNewData4eye(values) && "NEW");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  useEffect(() => {
    if (is4eye) {
      setIsReviewerUpdate(true);
      setIsReasonUpdate(true);
    }
  }, [is4eye]);

  useEffect(() => {
    if (Object.keys(errors).length > 0) {
      stepsArray[activeStep].error = true;
      stepsArray[activeStep].completed = false;
    } else {
      stepsArray[activeStep].error = false;
      stepsArray[activeStep].completed = true;
    }
    const truthness = stepsArray.some(function (element) {
      return element.error;
    });
    if (truthness === true) {
      setDisabledFinishButton(true);
      setDisableSave(true);
    } else {
      if (activeStep === 4) {
        setDisabledFinishButton(false);
        setAllStepComplete(true);
      } else if (allStepComplete || isUpdateBadge) {
        setDisabledFinishButton(!((allStepComplete || isUpdateBadge) && (Object.keys(errors).length === 0 || isValid)));
      } else {
        setDisabledFinishButton(true);
      }
      if (isEditMode) {
        const formError = stepsArray.find((x) => x.error === true);
        if (!formError) {
          const tempInstrumentDetail = removeKeys(editInstrumentDetail);
          const tempvalues = removeKeys(values);
          setDisableSave(() => isEqual(tempInstrumentDetail, tempvalues));
        }
      } else {
        setDisableSave(false);
      }
    }
  }, [
    activeStep,
    allStepComplete,
    editInstrumentDetail,
    errors,
    isEditMode,
    isUpdateBadge,
    isValid,
    stepsArray,
    values
  ]);

  /**
   * This effect is used to trigger the setEquipmentValueOnEdit method
   * this triggers only if the instrument is passed upon edit mode
   */
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      if (selectedItem?.id) {
        const result = await checkUnicityForEquipmentOnEdit(selectedItem.id, client);
        if (result) {
          const originalValues = await handleFieldValuesForEdit(result);
          setOriginalEquipmentValues(cloneDeep(originalValues));
          setIs4eye(isData4eye(originalValues));
        }
      }
      setIsLoading(false);
    };
    if (isEditMode) {
      setIsLinkedInstance(true);
      fetchData();
    } else {
      setActiveStep(0);
      updateInstrumentDetail({
        ...emptyInstrument,
        siteName: user?.site
      });
      resetForm({
        values: {
          ...emptyInstrument,
          siteName: user?.site
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Simple function to update list of reviewers according to selected group.
   * @return {Promise<void>}
   */
  const updateReviewers = async () => {
    const list = await getReviewers(client, values.belongingToGroup, user);
    setReviewers(() => list);
  };

  const removeKeys = (updatedDetail) => {
    return {
      ...updatedDetail,
      buildingLocation: updatedDetail?.buildingLocation
        ? {
            buildingNumber: updatedDetail?.buildingLocation?.buildingNumber,
            id: updatedDetail?.buildingLocation?.buildingNumber,
            siteId: updatedDetail?.buildingLocation?.buildingNumber
          }
        : null,
      floor: updatedDetail?.floor
        ? {
            buildingsId: updatedDetail?.floor?.buildingsId,
            id: updatedDetail?.floor?.id,
            floorCode: updatedDetail?.floor?.floorCode,
            siteId: updatedDetail?.floor?.siteId
          }
        : null,
      room: updatedDetail?.room
        ? {
            id: updatedDetail?.room?.id,
            roomCode: updatedDetail?.room?.roomCode,
            siteId: updatedDetail?.room?.siteId
          }
        : null,
      variant: updatedDetail?.variant ? updatedDetail?.variant : "",
      qualificationDocuments:
        updatedDetail?.qualificationDocuments?.value === undefined ? [] : updatedDetail?.qualificationDocuments?.value,
      dateOfNextPeriodicReview:
        typeof updatedDetail?.dateOfNextPeriodicReview === "string"
          ? updatedDetail?.dateOfNextPeriodicReview
          : changeDateFormat(updatedDetail?.dateOfNextPeriodicReview, "yyyy-MM-dd"),
      dateOfLastMaintanance:
        typeof updatedDetail?.dateOfLastMaintanance === "string"
          ? updatedDetail?.dateOfLastMaintanance
          : changeDateFormat(updatedDetail?.dateOfLastMaintanance, "yyyy-MM-dd"),

      dateOfNextMaintanance:
        typeof updatedDetail?.dateOfNextMaintanance === "string"
          ? updatedDetail?.dateOfNextMaintanance
          : changeDateFormat(updatedDetail?.dateOfNextMaintanance, "yyyy-MM-dd"),
      softwareVersion: updatedDetail?.softwareVersion ? updatedDetail?.softwareVersion : "",
      installedTests: updatedDetail?.installedTests
        ? updatedDetail?.installedTests?.length === 0
          ? null
          : updatedDetail?.installedTests
        : null
    };
  };

  /**
   * This method is used to get the selected instrument and populated the form fields
   * @param {instrument} instrumentObj the current instrument that is selected
   * the values of the instrument will be updated as per the fields
   */
  const setEquipmentValueOnEdit = (instrumentObj) => {
    for (const key in instrumentObj) {
      values[key] = instrumentObj[key];
    }
    setValues({ ...values });
  };

  const setValuesinRedux = () => {
    for (const key in values) {
      instrumentDetail[key] = values[key];
    }
    updateInstrumentDetail(instrumentDetail);
  };

  const updateReasons = async () => {
    setValuesinRedux();
    let tempUpdatedRecord = omitDeep(
      updateReviewInstrumentAction(),
      "editReason",
      "editComment",
      "reviewers"
    );

    [
      "serialNumber",
      "softwareVersion",
      "equipmentModel",
      "responsiblePerson",
      "belongingToGroup",
      "manufacturer",
    ].forEach((field) => {
      tempUpdatedRecord[field] = tempUpdatedRecord?.[field] === "null" ? null : tempUpdatedRecord?.[field];
    });

    if (is4eye) {
      await updateReviewers();
    }

    setIsReviewerUpdate(() => !!is4eye);
    setIsReasonUpdate(() => true);
    openDialog();
    setIsLoading(false);
  };

  const updateReasonsData = async () => {
    const reasonData = await getReasons(client);
    loadReasonData(reasonData);
  };

  const setGxpTempValues = (instrumentDetail) => {
    for (const key in instrumentDetail) {
      if (GXP_DROP_CHECK.includes(key)) {
        instrumentDetail[key] = instrumentDetail[key]?.key;
      }
    }

    return cloneDeep(instrumentDetail);
  };

  const isAnalyser = (values) => {
    if (!isFromSap) {
      values.softwareVersion = "";
      values.installedTests = [];
      values.configurationBaseline = "";
    }
  };

  /**
   * This method is used to handle the previous button click on the add wizard
   * it updates the activeSetp value by decreasing one through setactiveStep props
   */
  const handlePreviousButton = () => {
    let newArray = [...stepsArray];
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    newArray.map((item) => (item.active = false));
    if (isEditMode) {
      setActiveStep(() => activeStep - 1);
      newArray[activeStep - 1].active = true;
    } else if (!isEditMode) {
      setActiveStep(() => activeStep - 1);
      newArray[activeStep - 1].active = true;
    } else {
      newArray[activeStep - 1].active = true;
    }
    if (Object.keys(errors).length > 0) {
      newArray[activeStep].error = true;
      newArray[activeStep].completed = false;
    } else {
      newArray[activeStep].error = false;
      newArray[activeStep].completed = true;
    }
    setStepsArray(newArray);
    setErrors({});
  };

  /**
   * This method is used to handle the next button click on the add wizard
   * it submits the form and if valid moves to the next step, unicity check is also handled
   */
  const handleNextButton = async () => {
    setTempSelectedInstrument(null);
    let newArray = [...stepsArray];
    newArray[activeStep].valid = true;
    if (!isEditMode) {
      newArray[activeStep].completed = true;
    }
    newArray.map((item) => (item.active = false));
    if (Object.keys(errors).length === 0) {
      newArray[activeStep].error = false;
      newArray[activeStep].completed = true;
    }

    const manipulateAnalyzerStep = () => {
      if (isFromSap) {
        isAnalyser(values);
      }
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      newArray[activeStep + 1].completed = true;
      newArray[activeStep + 1].active = true;
      setValuesinRedux();
    };

    if (isEditMode) {
      switch (activeStep) {
        case 0:
          if (isValid) {
            setIsEditMode(true);
            setValuesinRedux();
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
            newArray[activeStep + 1].active = true;
          }
          break;
        case 1:
        case 2:
        case 4:
          setValuesinRedux();
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
          newArray[activeStep + 1].active = true;
          break;
        case 3:
          manipulateAnalyzerStep();
          break;
        default:
          break;
      }
    } else {
      switch (activeStep) {
        case 0:
          if (isValid) {
            const enteredLinkedInstance = values?.linkedInstance;
            const enteredsecondaryID = secondaryIdMappingFields?.map((field) => values?.[field]).join(";");
            const checkedInstrument = await checkUnicityForEquipmentOnAdd(
              enteredLinkedInstance,
              enteredsecondaryID,
              client,
              setIsLoading
            );
            if (checkedInstrument?.length < 1) {
              if (enteredLinkedInstance.linkedInstanceDisplay === "Molecular") {
                setIsFetchFromSap(true);
                if (isFetchSuccess) {
                  setIsLinkedInstance(true);
                  setActiveStep((prevActiveStep) => prevActiveStep + 1);
                  newArray[activeStep + 1].active = true;
                } else {
                  setValues({
                    ...values,
                    linkedInstance: enteredLinkedInstance,
                    secondaryID: enteredsecondaryID
                  });
                  setValuesinRedux();
                  setIsLinkedInstance(true);
                  setActiveStep((prevActiveStep) => prevActiveStep + 1);
                  newArray[activeStep + 1].active = true;
                }
              } else {
                setValues({
                  ...values,
                  linkedInstance: enteredLinkedInstance,
                  secondaryID: enteredsecondaryID
                });
                setValuesinRedux();
                setIsLinkedInstance(true);
                setActiveStep((prevActiveStep) => prevActiveStep + 1);
                newArray[activeStep + 1].active = true;
              }
            } else {
              newArray[0].active = true;
              Notify({
                type: "warning",
                icon: "caution",
                appName: "",
                text: "This equipment already exists."
              });
            }
          }
          break;
        case 1:
        case 2:
        case 4:
          setValuesinRedux();
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
          newArray[activeStep + 1].active = true;
          break;
        case 3:
          manipulateAnalyzerStep();
          break;
        default:
          break;
      }
    }
    setStepsArray(newArray);
  };

  /**
   * This method is used to manipulate the instrument data entry based on the data fetched from SAP
   * @param {object} data An Object that conatins instrument details fetched from SAP for corresponding serial number and material number
   */
  const getDataFromDataRiver = async (data) => {
    let dataFromSap;
    setIsFromSap(true);
    // find the misMatchedData from the Sap
    let misMatchedData;
    misMatchedData = UPDATE_SAP_MANDATORY_FIELDS.filter(
      (item) => !Object.keys(data).includes(item.id) || !data[item.id]
    );

    // need to remove manufacturer if value does not match the dropdown values from sap
    if (data?.manufacturer) {
      if (!listOptions?.manufacturerList.includes(data?.manufacturer)) {
        data.manufacturer = null;
        misMatchedData.push(UPDATE_SAP_MANDATORY_FIELDS.find((item) => item.id === "manufacturer"));
      }
    }

    // need to remove buildingLocation if value does not match the dropdown values from sap
    if (data?.buildingLocation?.value) {
      const buildingObj = await getBuilding(client, data?.buildingLocation?.key);
      if (!buildingObj) {
        data.buildingLocation = null;
        misMatchedData.push(UPDATE_SAP_MANDATORY_FIELDS.find((item) => item.id === "buildingLocation"));
      } else {
        data.buildingLocation = {
          key: buildingObj.id,
          value: buildingObj.buildingNumber
        };
      }
    } else {
      data.buildingLocation = null;
      misMatchedData.push(UPDATE_SAP_MANDATORY_FIELDS.find((item) => item.id === "buildingLocation"));
    }
    // need to remove floor if value does not match the dropdown values from sap
    if (data?.floor?.value) {
      const floorObj = await getFloor(client, data?.floor?.key);
      if (!floorObj) {
        data.floor = null;
        misMatchedData.push(UPDATE_SAP_MANDATORY_FIELDS.find((item) => item.id === "floor"));
      } else {
        data.floor = {
          key: floorObj.id,
          value: floorObj.floorCode
        };
      }
    } else {
      data.floor = null;
      misMatchedData.push(UPDATE_SAP_MANDATORY_FIELDS.find((item) => item.id === "floor"));
    }

    // need to remove room if value does not match the dropdown values from sap
    if (data?.room?.value) {
      const roomObj = await getRoom(client, data?.room?.key);
      if (!roomObj) {
        data.room = null;
        misMatchedData.push(UPDATE_SAP_MANDATORY_FIELDS.find((item) => item.id === "room"));
      } else {
        data.room = {
          key: roomObj.id,
          value: roomObj.roomCode
        };
      }
    } else {
      data.room = null;
      misMatchedData.push(UPDATE_SAP_MANDATORY_FIELDS.find((item) => item.id === "room"));
    }

    // need to remove gxpRelevant if value does not match the dropdown values from sap
    if (data?.gxpRelevant) {
      if (
        !find(GXPemptyInstrumentsOptions?.gxpRelevant, {
          value: data?.gxpRelevant
        })
      ) {
        data.gxpRelevant = null;
        misMatchedData.push(UPDATE_SAP_MANDATORY_FIELDS.find((item) => item.id === "gxpRelevant"));
      }
    }

    if (data?.equipmentModel) {
      if (!listOptions?.modelList.includes(data?.equipmentModel)) {
        data.equipmentModel = null;
        misMatchedData.push(UPDATE_SAP_MANDATORY_FIELDS.find((item) => item.id === "equipmentModel"));
      }
    }

    dataFromSap = {
      ...data,
      secondaryIDMapping: values?.linkedInstance?.secondaryIdMapping
    };

    // set the data to formik and redux
    handleFieldValuesForEdit(dataFromSap, true);

    // trigger field validation if mandatory attribute not present
    misMatchedData.forEach((item) => {
      setFieldTouched(item.id);
    });

    // display error toast message if any mandatory filed mis match with data
    if (misMatchedData.length > 0) {
      Notify({
        type: "warning",
        icon: "caution",
        appName: "",
        text: `SAP data for the following doesn't match the predefined list available: ${misMatchedData.map(
          (value) => ` ${value.label}`
        )}`
      });
    } else {
      Notify({
        type: "success",
        icon: "circle_confirm",
        appName: "",
        text: `Updated from SAP!`
      });
    }
    setIsFetchSuccess(true);
  };

  /**
   * This method is used to handle the action of user expanding parts of the form.
   * @param {Event} event event triggered by user
   */
  const expandedElementsChangedHandler = useCallback(
    (event) => {
      const currentStep = event?.detail[0];
      if (currentStep > activeStep || currentStep < activeStep) {
        const tempArray = cloneDeep(stepsArray);
        tempArray[activeStep].active = false;
        tempArray[currentStep].active = true;
        setActiveStep(() => currentStep);
        setStepsArray(tempArray);
      }
    },
    [activeStep, setActiveStep, setStepsArray, stepsArray]
  );

  const handleFieldValuesForEdit = async (instrument, isDataFromSAP = false) => {
    setSelectedItem(instrument);
    const tempInstrumentDetail = omitDeep(instrument, "__typename") || {};
    tempInstrumentDetail.maintenancePlan = tempInstrumentDetail?.maintenancePlan || "";
    tempInstrumentDetail.documentedMaintenanceDate = tempInstrumentDetail?.documentedMaintenanceDate || "";
    // enum values ================================
    if (tempInstrumentDetail.csv) {
      tempInstrumentDetail.csv =
        find(GXPemptyInstrumentsOptions?.csv, {
          key: tempInstrumentDetail?.csv || values?.csv?.key
        }) || null;
    }

    if (tempInstrumentDetail.linkedInstance) {
      tempInstrumentDetail.linkedInstance =
        find(linkedInstanceList, {
          linkedInstance: tempInstrumentDetail?.linkedInstance || values?.linkedInstance
        }) || null;
    }

    if (tempInstrumentDetail.electronicRecord) {
      tempInstrumentDetail.electronicRecord =
        find(GXPemptyInstrumentsOptions?.electronicRecord, {
          key: tempInstrumentDetail?.electronicRecord || values?.electronicRecord?.key
        }) || null;
    }

    if (tempInstrumentDetail.electronicSignatures) {
      tempInstrumentDetail.electronicSignatures =
        find(GXPemptyInstrumentsOptions?.electronicSignatures, {
          key: tempInstrumentDetail?.electronicSignatures || values?.electronicSignatures?.key
        }) || null;
    }

    if (tempInstrumentDetail.gxpRelevant) {
      tempInstrumentDetail.gxpRelevant =
        find(GXPemptyInstrumentsOptions?.gxpRelevant, {
          key: tempInstrumentDetail?.gxpRelevant || values?.gxpRelevant?.key
        }) || null;
    }

    if (tempInstrumentDetail.qualificationStatus || isDataFromSAP) {
      tempInstrumentDetail.qualificationStatus =
        find(GXPemptyInstrumentsOptions?.qualificationStatus, {
          key: tempInstrumentDetail?.qualificationStatus || values?.qualificationStatus?.key
        }) || null;
    }

    if (tempInstrumentDetail.controlledEquipmentStatus || isDataFromSAP) {
      tempInstrumentDetail.controlledEquipmentStatus =
        find(GXPemptyInstrumentsOptions?.controlledEquipmentStatus, {
          key: tempInstrumentDetail?.controlledEquipmentStatus || values?.controlledEquipmentStatus?.key
        }) || null;
    }

    if (tempInstrumentDetail?.equipmentCategory || isDataFromSAP) {
      tempInstrumentDetail.equipmentCategory = listOptions?.categoryList.includes(
        tempInstrumentDetail?.equipmentCategory
      )
        ? tempInstrumentDetail?.equipmentCategory
        : "";
    }

    if (tempInstrumentDetail?.manufacturer) {
      tempInstrumentDetail.manufacturer = listOptions?.manufacturerList.includes(tempInstrumentDetail?.manufacturer)
        ? tempInstrumentDetail?.manufacturer
        : "";
    }

    if (tempInstrumentDetail?.equipmentModel) {
      tempInstrumentDetail.equipmentModel = listOptions?.modelList.includes(tempInstrumentDetail?.equipmentModel)
        ? tempInstrumentDetail?.equipmentModel
        : "";
    }

    if (tempInstrumentDetail?.sop) {
      const sops = tempInstrumentDetail?.sop?.filter((sop) => {
        return find(listOptions?.sopList, {
          value: sop?.value
        });
      });
      tempInstrumentDetail.sop = sops || [];
    }

    if (tempInstrumentDetail?.softwareVersion) {
      tempInstrumentDetail.softwareVersion =
        tempInstrumentDetail?.softwareVersion === "null" ? null : tempInstrumentDetail?.softwareVersion;
    }

    if (tempInstrumentDetail?.belongingToGroup || isDataFromSAP) {
      tempInstrumentDetail.belongingToGroup =
        tempInstrumentDetail?.belongingToGroup === "null" ? null : tempInstrumentDetail?.belongingToGroup;
    }

    if (tempInstrumentDetail?.responsiblePerson) {
      tempInstrumentDetail.responsiblePerson =
        tempInstrumentDetail?.responsiblePerson === "null" ? null : tempInstrumentDetail?.responsiblePerson;
    }

    if (tempInstrumentDetail?.buildingLocation) {
      const building = await getBuilding(client, tempInstrumentDetail?.buildingLocation?.key);
      tempInstrumentDetail.buildingLocation = building?.isActive ? building : null;
    }

    if (tempInstrumentDetail?.floor) {
      const floor = await getFloor(client, tempInstrumentDetail?.floor?.key);
      tempInstrumentDetail.floor = floor?.isActive ? floor : null;
    }

    if (tempInstrumentDetail?.room) {
      const room = await getRoom(client, tempInstrumentDetail?.room?.key);
      tempInstrumentDetail.room = room?.isActive ? room : null;
    }

    tempInstrumentDetail.inventoryNumber = tempInstrumentDetail?.inventoryNumber?.toString() || "";
    if (tempInstrumentDetail?.inventoryNumber) {
      tempInstrumentDetail.inventoryNumber =
        tempInstrumentDetail?.inventoryNumber === "null" ? null : tempInstrumentDetail?.inventoryNumber;
    }
    tempInstrumentDetail.maintenanceIntervalInDays = tempInstrumentDetail?.maintenanceIntervalInDays
      ? tempInstrumentDetail?.maintenanceIntervalInDays?.toString() || ""
      : "";

    tempInstrumentDetail.instrumentGTIN = tempInstrumentDetail?.instrumentGTIN
      ? tempInstrumentDetail?.instrumentGTIN
      : "";

    tempInstrumentDetail.equipmentId =
      tempInstrumentDetail?.equipmentId === "null" || !tempInstrumentDetail?.equipmentId
        ? ""
        : tempInstrumentDetail?.equipmentId;

    tempInstrumentDetail.materialNumber =
      tempInstrumentDetail?.materialNumber === "null" || !tempInstrumentDetail?.materialNumber
        ? ""
        : tempInstrumentDetail?.materialNumber;

    tempInstrumentDetail.serialNumber =
      tempInstrumentDetail?.serialNumber === "null" || !tempInstrumentDetail?.serialNumber
        ? ""
        : tempInstrumentDetail?.serialNumber;

    tempInstrumentDetail.equipmentNickName =
      tempInstrumentDetail?.equipmentNickName === "null" || !tempInstrumentDetail?.equipmentNickName
        ? ""
        : tempInstrumentDetail?.equipmentNickName;

    tempInstrumentDetail.instrumentRUDI = tempInstrumentDetail?.instrumentRUDI
      ? tempInstrumentDetail?.instrumentRUDI
      : "";

    tempInstrumentDetail.tags = tempInstrumentDetail?.tags ? tempInstrumentDetail?.tags : [];

    if (
      !find(listOptions?.categoryMappingMasterList, {
        equipmentCategory: tempInstrumentDetail?.equipmentCategory,
        equipmentModel: tempInstrumentDetail?.equipmentModel,
        manufacturer: tempInstrumentDetail?.manufacturer
      })
    ) {
      tempInstrumentDetail.equipmentCategory = "";
      tempInstrumentDetail.equipmentModel = "";
      tempInstrumentDetail.manufacturer = "";
    }

    const misIRMatchedData = [];

    for (const key in tempInstrumentDetail) {
      const manditoryObj = find(isEditMode ? IR_EDIT_MANDATORY_FIELDS : IR_MANDATORY_FIELDS, { id: key });
      if (manditoryObj && !tempInstrumentDetail[key]) {
        misIRMatchedData.push(manditoryObj);
      }
    }

    if (tempInstrumentDetail?.instrumentGTIN) {
      let regex = new RegExp(/^(|\d)+$/);
      let result = regex.exec(tempInstrumentDetail?.instrumentGTIN);
      if (!result) {
        misIRMatchedData.push({
          id: DATA_MODEL_TABLE.instrumentGTIN.key,
          label: "GTIN",
          form: 0
        });
      }
    }

    misIRMatchedData.forEach((item) => {
      setFieldTouched(item.id);
    });

    if (misIRMatchedData.length > 0) {
      const updatedStepsArray = stepsArray.map((e) => {
        return find(misIRMatchedData, { form: e.id })
          ? { ...e, error: true, completed: false }
          : { ...e, error: false, completed: true };
      });
      setStepsArray(updatedStepsArray);
    } else {
      const updatedStepsArray = stepsArray.map((e) => {
        return { ...e, error: false, completed: true };
      });
      setStepsArray(updatedStepsArray);
    }
    updateReasonsData();
    updateInstrumentDetail(tempInstrumentDetail);
    updateEditInstrumentDetail(tempInstrumentDetail);
    setEquipmentValueOnEdit(tempInstrumentDetail);
    return tempInstrumentDetail;
  };

  const saveOrUpdateInstrumentAction = async () => {

    setIsLoading(true);

    setValuesinRedux();

    const updatedInstrumentData = setGxpTempValues(instrumentDetail);

    updatedInstrumentData.dateOfNextPeriodicReview = changeDateFormat(
      updatedInstrumentData.dateOfNextPeriodicReview,
      "yyyy-MM-dd"
    );
    updatedInstrumentData.dateOfLastMaintanance = changeDateFormat(
      updatedInstrumentData.dateOfLastMaintanance,
      "yyyy-MM-dd"
    );

    updatedInstrumentData.dateOfNextMaintanance = changeDateFormat(
      updatedInstrumentData.dateOfNextMaintanance,
      "yyyy-MM-dd"
    );

    updatedInstrumentData.siteId = find(sites, {
      siteName: instrumentDetail.siteName
    })?.siteId;

    updatedInstrumentData.siteTimezone = find(sites, {
      siteName: instrumentDetail.siteName
    })?.timeZone;

    if (updatedInstrumentData?.tags && updatedInstrumentData?.tags?.length > 0) {
      updatedInstrumentData.tags.sort();
    }

    if (updatedInstrumentData?.buildingLocation) {
      updatedInstrumentData.buildingLocation = {
        key: updatedInstrumentData?.buildingLocation?.id,
        value: updatedInstrumentData?.buildingLocation?.buildingNumber
      };
    }

    if (updatedInstrumentData?.floor) {
      updatedInstrumentData.floor = {
        key: updatedInstrumentData?.floor?.id,
        value: updatedInstrumentData?.floor?.floorCode
      };
    }

    if (updatedInstrumentData?.room) {
      updatedInstrumentData.room = {
        key: updatedInstrumentData?.room?.id,
        value: updatedInstrumentData?.room?.roomCode
      };
    }

    if (updatedInstrumentData?.linkedInstance) {
      updatedInstrumentData.linkedInstance = updatedInstrumentData?.linkedInstance?.linkedInstance;
    }

    updatedInstrumentData.materialNumber = updatedInstrumentData?.materialNumber
      ? updatedInstrumentData?.materialNumber.trim()
      : null;

    updatedInstrumentData.serialNumber = updatedInstrumentData.serialNumber
      ? updatedInstrumentData?.serialNumber.trim()
      : "null";

    if (!updatedInstrumentData.inventoryNumber) {
      updatedInstrumentData.inventoryNumber = null;
    } else {
      updatedInstrumentData.inventoryNumber = parseInt(updatedInstrumentData.inventoryNumber);
    }

    if (!updatedInstrumentData.maintenanceIntervalInDays) {
      updatedInstrumentData.maintenanceIntervalInDays = null;
    } else {
      updatedInstrumentData.maintenanceIntervalInDays = parseInt(updatedInstrumentData.maintenanceIntervalInDays);
    }

    if (isEditMode) {
      updatedInstrumentData.editReason = updatedInstrumentData.configText;

      delete updatedInstrumentData.configText;
      delete updatedInstrumentData.linkedInstanceRef;
      delete updatedInstrumentData.subEquipment;
    } else {
      updatedInstrumentData.entryType = entryType?.standaloneEquipment;
    }
    for (const key in updatedInstrumentData) {
      if (typeof updatedInstrumentData[key] === "string") {
        updatedInstrumentData[key] = updatedInstrumentData[key]?.trim() || "";
      }
      if (!updatedInstrumentData.editComment) {
        updatedInstrumentData.editComment = "";
      }
    }

    let inputData = omitDeep(updatedInstrumentData, "__typename");
    let mutation = UPDATE_EQUIPMENT_WITH_REVIEW;
    let variables;
    inputData.reviewers = inputData?.reviewers?.map((obj) => obj?.userId ?? obj);
    if (is4eye) {
      inputData.status = equipmentStatus.pending.key;
      inputData.id = uuidv4();
      inputData.editReason = "";
      variables = {
        inventoryId: inputData.id,
        newData: inputData,
        reviewers: inputData.reviewers
      };
    } else {
      !isEditMode && delete inputData.reviewers;
      mutation = isEditMode
        ? UPDATE_DIGITAL_LAB_INSTRUMENT_REPOSITORY_ENTRY
        : CREATE_DIGITAL_LAB_INSTRUMENT_REPOSITORY_ENTRY;
      variables = {
        instrument: inputData
      };
    }

    if (await validateFormOnSubmit(inputData)) {
      try {
        const result = await client.mutate({
          mutation,
          variables,
          fetchPolicy: "no-cache"
        });
        if (result) {
          if (result?.errors || result?.data?.updateEquipmentWithReview?.status === "FAILURE") {
            throw Error();
          }

          if (isEditMode) {
            await handleFieldValuesForEdit(result?.data?.updateDigitalLabInstrumentRepositoryEntry);
          }

          const instruments = await getEquipmentListAndUpdateRedux(client, limit);
          loadInstruments({ instruments });
          Notify({
            type: "success",
            icon: "circle_confirm",
            appName: "",
            text: isEditMode
              ? `Equipment updated successfully! `
              : is4eye
              ? `Equipment created and notifications sent to the reviewer(s) successfully!`
              : `Equipment created successfully! `
          });
          cancelStep();
        } else {
          Notify({
            type: "warning",
            icon: "caution",
            appName: "",
            text: isEditMode ? `Equipment updated failed!` : `Equipment created failed! `
          });
        }
      } catch (error) {
        Notify({
          type: "warning",
          icon: "caution",
          appName: "",
          text: isEditMode ? `Equipment updated failed!` : `Equipment created failed! `
        });
      } finally {
        setIsLoading(false);
        setDisabledFinishButton(false);
      }
    }
  };

  const saveOrUpdateReviewer = async () => {
    setValuesinRedux();
    const updatedRecord = updateReviewInstrumentAction();
    let tempDetail = {
      ...updatedRecord,
      reviewers: updatedRecord?.reviewers.map((obj) => obj.userId)
    };
    setIsLoading(true);
    if (await validateFormOnSubmit(tempDetail)) {
      let tempUpdateDetail = {
        ...tempDetail,
        serialNumber: tempDetail?.serialNumber === "null" ? null : tempDetail?.serialNumber,
        softwareVersion: tempDetail?.softwareVersion === "null" ? null : tempDetail?.softwareVersion,
        equipmentModel: tempDetail?.equipmentModel === "null" ? null : tempDetail?.equipmentModel,
        responsiblePerson: tempDetail?.responsiblePerson === "null" ? null : tempDetail?.responsiblePerson,
        belongingToGroup: tempDetail?.belongingToGroup === "null" ? null : tempDetail?.belongingToGroup,
        manufacturer: tempDetail?.manufacturer === "null" ? null : tempDetail?.manufacturer
      };

      const result = await client.mutate({
        mutation: UPDATE_EQUIPMENT_WITH_REVIEW,
        variables: {
          inventoryId: tempUpdateDetail?.id,
          newData: tempUpdateDetail,
          reviewers: tempUpdateDetail?.reviewers
        },
        fetchPolicy: "no-cache"
      });

      if (result?.data?.updateEquipmentWithReview?.status === "SUCCESS") {
        Notify({
          type: "success",
          icon: "circle_confirm",
          appName: "",
          text: `Change(s) have been edited, and a notification has been sent to the reviewer(s) successfully.`
        });
      } else if (result?.data?.updateEquipmentWithReview?.status === "FAILURE") {
        Notify({
          type: "warning",
          icon: "caution",
          appName: "",
          text: `Equipment updated failed!`
        });
      } else {
        Notify({
          type: "warning",
          icon: "caution",
          appName: "",
          text: `Equipment updated failed!`
        });
      }

      if (isEditMode) {
        handleFieldValuesForEdit(result?.data?.updateDigitalLabInstrumentRepositoryEntry);
      }
      const instruments = await getEquipmentListAndUpdateRedux(client, limit);
      loadInstruments({ instruments });
      cancelStep();
      setIsLoading(false);
      setDisabledFinishButton(false);
    }
  };

  const updateReviewInstrumentAction = () => {
    setIsLoading(true);
    const updatedInstrumentData = setGxpTempValues(instrumentDetail);

    updatedInstrumentData.dateOfNextPeriodicReview =
      typeof updatedInstrumentData?.dateOfNextPeriodicReview === "string"
        ? updatedInstrumentData?.dateOfNextPeriodicReview
        : changeDateFormat(updatedInstrumentData?.dateOfNextPeriodicReview, "yyyy-MM-dd");
    updatedInstrumentData.dateOfLastMaintanance =
      typeof updatedInstrumentData?.dateOfLastMaintanance === "string"
        ? updatedInstrumentData?.dateOfLastMaintanance
        : changeDateFormat(updatedInstrumentData?.dateOfLastMaintanance, "yyyy-MM-dd");

    updatedInstrumentData.dateOfNextMaintanance =
      typeof updatedInstrumentData?.dateOfNextMaintanance === "string"
        ? updatedInstrumentData?.dateOfNextMaintanance
        : changeDateFormat(updatedInstrumentData?.dateOfNextMaintanance, "yyyy-MM-dd");

    updatedInstrumentData.siteId = find(sites, {
      siteName: instrumentDetail.siteName
    })?.siteId;

    updatedInstrumentData.siteTimezone = find(sites, {
      siteName: instrumentDetail.siteName
    })?.timeZone;

    if (updatedInstrumentData?.manufacturer) {
      updatedInstrumentData.manufacturer = updatedInstrumentData?.manufacturer ?? "";
    }
    if (updatedInstrumentData?.tags && updatedInstrumentData?.tags?.length > 0) {
      updatedInstrumentData.tags.sort();
    }

    if (updatedInstrumentData?.buildingLocation) {
      updatedInstrumentData.buildingLocation = {
        key: updatedInstrumentData?.buildingLocation?.id,
        value: updatedInstrumentData?.buildingLocation?.buildingNumber
      };
    }

    if (updatedInstrumentData?.floor) {
      updatedInstrumentData.floor = {
        key: updatedInstrumentData?.floor?.id,
        value: updatedInstrumentData?.floor?.floorCode
      };
    }

    if (updatedInstrumentData?.room) {
      updatedInstrumentData.room = {
        key: updatedInstrumentData?.room?.id,
        value: updatedInstrumentData?.room?.roomCode
      };
    }

    if (updatedInstrumentData?.linkedInstance) {
      updatedInstrumentData.linkedInstance = updatedInstrumentData?.linkedInstance?.linkedInstance;
    }

    updatedInstrumentData.materialNumber = updatedInstrumentData?.materialNumber
      ? updatedInstrumentData?.materialNumber.trim()
      : null;

    updatedInstrumentData.serialNumber = updatedInstrumentData.serialNumber
      ? updatedInstrumentData?.serialNumber.trim()
      : "null";

    if (!updatedInstrumentData.inventoryNumber) {
      updatedInstrumentData.inventoryNumber = null;
    } else {
      updatedInstrumentData.inventoryNumber = parseInt(updatedInstrumentData.inventoryNumber);
    }

    if (!updatedInstrumentData.maintenanceIntervalInDays) {
      updatedInstrumentData.maintenanceIntervalInDays = null;
    } else {
      updatedInstrumentData.maintenanceIntervalInDays = parseInt(updatedInstrumentData.maintenanceIntervalInDays);
    }

    if (isEditMode) {
      updatedInstrumentData.editReason = updatedInstrumentData.configText;

      delete updatedInstrumentData.configText;
      delete updatedInstrumentData.linkedInstanceRef;
      delete updatedInstrumentData.subEquipment;
    } else {
      updatedInstrumentData.entryType = entryType?.standaloneEquipment;
    }
    for (const key in updatedInstrumentData) {
      if (typeof updatedInstrumentData[key] === "string") {
        updatedInstrumentData[key] = updatedInstrumentData[key]?.trim() || "";
      }
      if (!updatedInstrumentData.editComment) {
        updatedInstrumentData.editComment = "";
      }
    }

    let inputData = omitDeep(updatedInstrumentData, "__typename");
    inputData = {
      ...inputData
    };
    setIsLoading(false);
    return inputData;
  };

  /**
   * Function executed when user wants to create new equipment
   * by clicking Finalize button or confirming data in modal.
   * @return {Promise<void>}
   */
  const handlerOnCreateEquip = async () => {
    setValuesinRedux();
    if (is4eye && !is4eyeFormOpen) {
      await updateReviewers();
      setIs4eyeFormOpen(true);
      reviewerDialog.openDialog();
      return;
    }
    // Track click on button
    trackEvent({ category: "Create Equipment", action: "click-event" });
    setDisabledFinishButton(true);
    saveOrUpdateInstrumentAction();
  };

  const handlerOnCancel = () => {
    if (is4eye && is4eyeFormOpen) {
      setIs4eyeFormOpen(false);
      reviewerDialog.close();
    }
  };

  const validateFormOnSubmit = async (inputData) => {
    let { equipmentModel, manufacturer, linkedInstance, modelName = "IREquipment", serialNumber = "null" } = inputData;

    if (linkedInstance !== "None") {
      linkedInstance = "None";

      const result = await client.query({
        query: LIST_IR_ENTRY_BY_MANUFACTURE_MODEL_SNO,
        fetchPolicy: "no-cache",
        variables: {
          equipmentModel,
          manufacturer,
          linkedInstance,
          modelName,
          serialNumber
        }
      });

      const unicityRecord = result?.data?.listIREntryByManufacturerModelAndSNo?.items;

      if (unicityRecord && unicityRecord.length > 0) {
        Notify({
          type: "warning",
          icon: "caution",
          appName: "",
          text: "Equipment with same Manufacturer, Model and Serial Number already exists in the repository."
        });
        setDisabledFinishButton(false);
        return false;
      }
    }

    return true;
  };

  /**
   * function that handle save for Edited equipment
   * @returns void
   */
  const handleSaveOnEdit = async() => {
    setIsLoading(true);
    if (isEditMode && values.linkedInstance.linkedInstance==="None") {
      const secondaryIdArray = values.secondaryIDMapping.split("; ")
      const secondaryId = `${values[secondaryIdArray[0]]};${values[secondaryIdArray[1]]};${values[secondaryIdArray[2]]}`
      //if new secodnaryId is different from one taht was stored in the DB
      if(secondaryId !==values.secondaryID) {
        setValues({...values,secondaryID:secondaryId});
        const enteredLinkedInstance = values?.linkedInstance;
        //checkUnicityForEquipmentOnAdd is used becasue it fetches all instances of secondaryID that hase unique key as provided in request
        const checkedInstrument = await checkUnicityForEquipmentOnAdd(
          enteredLinkedInstance,
          secondaryId,
          client
        );
        //if one instance fetched is different than edited
        if (checkedInstrument.some(i=>i.id!==values.id)) {
          Notify({
            type: "warning",
            icon: "caution",
            appName: "",
            text: "Equipment with same Manufacturer, Model and Serial Number already exists in the repository."
          });
        setIsLoading(false);
        return;
        }
      }
    }
    //if all goes well, we continue
    updateReasons();
  }

  /**
   * fucntion taht enables/disables next button on edit page1
   * @returns {Boolean} value to disable/enable next button
   */
  const isNextDisabled =() => {
    return !isEditMode && activeStep === 0 && !values?.linkedInstance
    ? true
    : Object.keys(errors).length > 0 || !isValid || isLoading || activeStep === (isEditMode ? 4 : 5)
  }

  return (
    <>
      {!isReasonUpdate &&
        (isEditMode ? (
          <ConfirmDialog
            {...dialogProps}
            approveText="Edit"
            approveColor="primary"
            approveVariant="contained"
            cancelText="Cancel"
            cancelVariant="outlined"
            cancelColor="primary"
            onApprove={() => {
              handleFieldValuesForEdit(tempSelectedInstrument);
            }}
            title="Add equipment"
            content="This equipment already exists. Do you want to edit it ?"
            disableBackdropClick={true}
          />
        ) : (
          <ConfirmDialog
            {...dialogProps}
            cancelText="Cancel"
            cancelVariant="outlined"
            cancelColor="primary"
            title="Add equipment"
            content="This equipment already exists."
            disableBackdropClick={true}
          />
        ))
      }

      <OwcWorkflowOverlay
        className="owcoverayZIndex"
        visible={activeModule === MODIFY_INSTRUMENT}
        onVisibleChange={(evt) => {
          if (evt?.target?.["data-testid"] !== "confirm-dialog") {
            cancelStep();
          }
        }}
      >
        <div slot="title">{isEditMode ? "Edit equipment details" : "Add equipment"}</div>
        {
          <>
            <div
              slot="content"
              style={{
                padding: "0",
                display: "flex",
                flexDirection: "column",
                marginBottom: "0",
                height: "100%"
              }}
            >
              {!isEditMode && (
                <StepperElement
                  activeStep={activeStep}
                  setActiveStep={setActiveStep}
                  isEditMode={isEditMode}
                  errors={errors}
                />
              )}
              <AddContainer>
                <AddContainerBody style={{ height: "100%", padding: "20px 16px" }}>
                  {isEditMode ? (
                    isLoading
                    ? (
                        <CenteredSpinner/>
                      )
                    : (
                      <EditStepContent
                        expandID={activeStep}
                        isEditMode={isEditMode}
                        isUpdateBadge={isUpdateBadge}
                        sopHelperText={sopHelperText}
                        isLinkedInstance={isLinkedInstance}
                        expandableGroupElement={expandableGroupElement}
                        expandedElementsChangedHandler={expandedElementsChangedHandler}
                      />
                    )
                  ) : (
                    <AddStepContent
                      step={activeStep}
                      isEditMode={isEditMode}
                      isUpdateBadge={isUpdateBadge}
                      sopHelperText={sopHelperText}
                      isLinkedInstance={isLinkedInstance}
                    />
                  )}
                </AddContainerBody>
              </AddContainer>
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                height: "calc(100% - 120px)",
                backgroundColor: "var(--one-color-interaction-hover-brand-3)"
              }}
            >
              <div
                style={{
                  width: "60%",
                  margin: "auto",
                  height: "calc(100% - 60px)",
                  backgroundColor: "var(--one-color-interaction-disabled-base-1)"
                }}
              />
            </div>
          </>
        }
        <div slot="actions" style={{ padding: "16px" }}>
          <div style={{ display: "flex", flexGrow: "1" }}>
            {activeStep !== 0 && !isEditMode && (
              <OwcButton
                data-testid="add-instrument-previous-step-button"
                variant="secondary"
                onClick={handlePreviousButton}
                disabled={isLoading}
              >
                Previous
              </OwcButton>
            )}
          </div>
          <div style={{ display: "flex" }}>
            {<CancelButton disabled={isLoading} cancelStep={cancelStep} />}
            {isEditMode && (
              <>
                <OwcButton
                  variant="primary"
                  data-testid="add-instrument-next-step-button"
                  disabled={disableSave || !dirty || Object.keys(errors).length > 0 || isLoading}
                  onClick={() => handleSaveOnEdit()}
                  style={{
                    textTransform: "none",
                    marginRight: !(activeStep === steps.length - 1) ? "20px" : ""
                  }}
                >
                  Save
                </OwcButton>
                {isReviewerUpdate ? (
                  <ConfirmDialog
                    {...dialogProps}
                    isReasonUpdate={isReasonUpdate}
                    isReviewerUpdate={isReviewerUpdate}
                    reviewers={reviewers}
                    approveText="Save"
                    approveColor="primary"
                    approveVariant="contained"
                    cancelText="Cancel"
                    cancelVariant="outlined"
                    cancelColor="primary"
                    onApprove={() => saveOrUpdateReviewer()}
                    title="Reason for edit"
                    disableBackdropClick={true}
                  />
                ) : (
                  <ConfirmDialog
                    {...dialogProps}
                    isReasonUpdate={isReasonUpdate}
                    approveText="OK"
                    approveColor="primary"
                    approveVariant="contained"
                    cancelText="Cancel"
                    cancelVariant="outlined"
                    cancelColor="primary"
                    onApprove={() => saveOrUpdateInstrumentAction()}
                    title="Reason for editing equipment"
                    disableBackdropClick={true}
                  />
                )}
              </>
            )}
            {activeStep >= 0 && activeStep < steps?.length - 1 && !isEditMode && (
              <OwcButton
                variant="primary"
                disabled= {isNextDisabled()}
                type="submit"
                onClick={() => handleNextButton()}
                data-testid="add-instrument-next-step-button"
              >
                Next
              </OwcButton>
            )}
            {isFetchFromSap && (
              <FetchInstrumentFromDataRiver
                setIsUpdateBadge={setIsUpdateBadge}
                materialNumber={values.materialNumber}
                serialNumber={values.serialNumber}
                onFetched={getDataFromDataRiver}
              />
            )}
            {!isEditMode && activeStep === steps?.length - 1 && (
              <OwcButton
                variant="primary"
                disabled={isLoading || disabledFinishButton || Object.keys(errors).length > 0}
                onClick={() => handlerOnCreateEquip()}
                data-testid="add-instrument-next-step-button"
              >
                Finalize
              </OwcButton>
            )}
          </div>
        </div>
      </OwcWorkflowOverlay>
      <AddReviewerWithoutReason
        {...reviewerDialog}
        reviewers={reviewers}
        isReviewerUpdate={isReviewerUpdate}
        approveText="Save"
        approveColor="primary"
        approveVariant="contained"
        cancelText="Cancel"
        cancelVariant="outlined"
        cancelColor="primary"
        onCancel={handlerOnCancel}
        onApprove={handlerOnCreateEquip}
        title="Reviewer required for new equipment"
        disableBackdropClick={true}
      />
    </>
  );
};
const mapStateToProps = (state) => ({
  instrumentDetail: state.instruments?.instrumentDetail,
  editInstrumentDetail: state.instruments?.editInstrumentDetail,
  linkedInstanceList: state.instruments?.linkedInstanceList,
  buildingDataOnSite: state.instruments?.buildingDataOnSite,
  floorDataOnSite: state.instruments?.floorDataOnSite,
  roomDataOnSite: state.instruments?.roomDataOnSite,
  limit: state.instruments?.limit,
  user: state.user,
  sites: state.user.sites
});
export default compose(
  connect(mapStateToProps, {
    updateInstrumentDetail: updateInstrumentDetailAction,
    updateEditInstrumentDetail: updateEditInstrumentDetailAction,
    loadInstruments: loadInstrumentsAction,
    loadBuildingData: loadBuildingDataAction,
    loadFloorData: loadFloorDataAction,
    loadRoomData: loadRoomDataAction,
    loadReasonData: loadReasonDataAction
  }),
  withApollo
)(AddInstrumentContainer);
