import DATA_MODEL_TABLE from "../../../utils/constants/dataModelTable";

export const TOOL_PANELS = {
  columns: "columns",
  filters: "filters",
  saveConfiguration: "save_configuration"
};

export const entryType = {
  cluster: "CLUSTER",
  standaloneEquipment: "STANDALONE-EQUIPMENT",
  clusterSubequipment: "CLUSTER-SUBEQUIPMENT"
};

export const MY_LAB = "My lab";

export const SOP_RT = "SOP RT0020";

export const YES = "Yes";

export const PREDEFINED_FILTER_NAME = "Predefined filter";

export const SELECT_PREDEFINED_FILTER_NAME = "Select predefined filter";

export const PREDEFINED_COLUMN_NAME = "Predefined column";

export const SELECT_PREDEFINED_COLUMN_NAME = "Select predefined column";

export const filterKeyMapping = {
  serialNumber: DATA_MODEL_TABLE?.serialNumber?.key,
  materialNumber: DATA_MODEL_TABLE?.materialNumber?.key,
  equipmentId: DATA_MODEL_TABLE?.equipmentId?.key,
  tags: DATA_MODEL_TABLE?.tags?.key,
  linkedInstance: DATA_MODEL_TABLE?.linkedInstance?.key,
  site: DATA_MODEL_TABLE?.siteName?.key,
  group: DATA_MODEL_TABLE?.belongingToGroup?.key,
  manufacturer: DATA_MODEL_TABLE?.manufacturer?.key,
  category: DATA_MODEL_TABLE?.equipmentCategory?.key,
  responsiblePerson: DATA_MODEL_TABLE?.responsiblePerson?.key,
  equipmentAdministrator: DATA_MODEL_TABLE?.equipmentAdministrator?.key,
  dateOfNextMaintanance: DATA_MODEL_TABLE?.dateOfNextMaintanance?.key,
  dateFrom: "dateFrom",
  dateTo: "dateTo",
  inventoryIds: "_id"
};

export const GXPemptyInstrumentsOptions = {
  csv: [
    { key: "NA", value: "NA" },
    { key: "No", value: "No" },
    { key: "Yes", value: "Yes" }
  ],
  electronicRecord: [
    { key: "NA", value: "NA" },
    { key: "No", value: "No" },
    { key: "Yes", value: "Yes" }
  ],
  electronicSignatures: [
    { key: "NA", value: "NA" },
    { key: "No", value: "No" },
    { key: "Yes", value: "Yes" }
  ],
  gxpRelevant: [
    { key: "No", value: "No" },
    { key: "Yes", value: "Yes" }
  ],
  qualificationStatus: [
    {
      key: "CHANGED_COMPONENT_ONLY",
      value: "Changed component only"
    },
    { key: "NA", value: "NA" },
    { key: "NOT_QUALIFIED", value: "Not qualified" },
    { key: "QUALIFIED", value: "Qualified" }
  ],
  controlledEquipmentStatus: [
    { key: "CALIBRATED", value: "Calibrated" },
    { key: "NA", value: "NA" },
    { key: "NOT_CALIBRATED", value: "Not calibrated" }
  ],
  dateOfNextMaintanance: null,
  dateOfLastMaintanance: null
};

export const dropDownSelectionSource = {
  agGridFilter: "AG_GRID_FILTER",
  agGridColumn: "AG_GRID_COLUMN",
  nonAgGridFilter: "NON_AG_GRID_FILTER",
  nonAgGridColumn: "NON_AG_GRID_COLUMN"
};

export const allTableColumn = () => [
  {
    key: DATA_MODEL_TABLE.serialNumber.key,
    val: DATA_MODEL_TABLE.serialNumber.value,
    order: 1,
    show: true,
    sortStatus: true
  },
  {
    key: DATA_MODEL_TABLE.materialNumber.key,
    val: DATA_MODEL_TABLE.materialNumber.value,
    order: 2,
    show: true,
    sortStatus: false
  },
  {
    key: DATA_MODEL_TABLE.equipmentModel.key,
    val: DATA_MODEL_TABLE.equipmentModel.value,
    order: 3,
    show: true,
    sortStatus: true
  },
  {
    key: DATA_MODEL_TABLE.siteName.key,
    val: DATA_MODEL_TABLE.siteName.value,
    order: 4,
    show: true,
    sortStatus: false
  },
  {
    key: DATA_MODEL_TABLE.manufacturer.key,
    val: DATA_MODEL_TABLE.manufacturer.value,
    order: 5,
    show: true,
    sortStatus: false
  },
  {
    key: DATA_MODEL_TABLE.instrumentGTIN.key,
    val: "GTIN number",
    order: 6,
    show: false,
    sortStatus: false
  },
  {
    key: DATA_MODEL_TABLE.equipmentId.key,
    val: DATA_MODEL_TABLE.equipmentId.value,
    order: 7,
    show: false,
    sortStatus: false
  },
  {
    key: DATA_MODEL_TABLE.location.key,
    val: DATA_MODEL_TABLE.location.value,
    order: 8,
    show: false,
    sortStatus: true
  },
  {
    key: DATA_MODEL_TABLE.responsiblePerson.key,
    val: DATA_MODEL_TABLE.responsiblePerson.value,
    order: 9,
    show: false,
    sortStatus: true
  },
  {
    label: DATA_MODEL_TABLE.checkInRequired.value,
    field: DATA_MODEL_TABLE.checkInRequired.key,
    props: {
      fullRow: false
    }
  },
  {
    label: DATA_MODEL_TABLE.checkInStartPeriod.value,
    field: DATA_MODEL_TABLE.checkInStartPeriod.key,
    props: {
      fullRow: false
    }
  },
  {
    label: DATA_MODEL_TABLE.checkInGracePeriod.value,
    field: DATA_MODEL_TABLE.checkInGracePeriod.key,
    props: {
      fullRow: false
    }
  },
  {
    key: DATA_MODEL_TABLE.isBookable.key,
    val: DATA_MODEL_TABLE.isBookable.value,
    order: 10,
    show: false,
    sortStatus: false
  },
  {
    key: DATA_MODEL_TABLE.instrumentRUDI.key,
    val: "RUDI number",
    order: 11,
    show: false,
    sortStatus: false
  },
  {
    key: DATA_MODEL_TABLE.equipmentCategory.key,
    val: DATA_MODEL_TABLE.equipmentCategory.value,
    order: 12,
    show: false,
    sortStatus: false
  },
  {
    key: DATA_MODEL_TABLE.comment.key,
    val: DATA_MODEL_TABLE.comment.value,
    order: 13,
    show: false,
    sortStatus: false
  },
  {
    key: DATA_MODEL_TABLE.belongingToGroup.key,
    val: DATA_MODEL_TABLE.belongingToGroup.value,
    order: 14,
    show: false,
    sortStatus: true
  },
  {
    key: DATA_MODEL_TABLE.room.key,
    val: DATA_MODEL_TABLE.room.value,
    order: 15,
    show: false,
    sortStatus: false
  },
  {
    key: DATA_MODEL_TABLE.systemOwner.key,
    val: DATA_MODEL_TABLE.systemOwner.value,
    order: 16,
    show: false,
    sortStatus: false
  },
  {
    key: DATA_MODEL_TABLE.responsibleProxy.key,
    val: DATA_MODEL_TABLE.responsibleProxy.value,
    order: 17,
    show: false,
    sortStatus: false
  },
  {
    key: DATA_MODEL_TABLE.sop.key,
    val: DATA_MODEL_TABLE.sop.value,
    order: 18,
    show: false,
    sortStatus: false
  },
  {
    key: DATA_MODEL_TABLE.csv.key,
    val: DATA_MODEL_TABLE.csv.value,
    order: 19,
    show: false,
    sortStatus: false
  },
  {
    key: DATA_MODEL_TABLE.electronicRecord.key,
    val: DATA_MODEL_TABLE.electronicRecord.value,
    order: 20,
    show: false,
    sortStatus: false
  },
  {
    key: DATA_MODEL_TABLE.electronicSignatures.key,
    val: DATA_MODEL_TABLE.electronicSignatures.value,
    order: 21,
    show: false,
    sortStatus: false
  },
  {
    key: DATA_MODEL_TABLE.dateOfNextPeriodicReview.key,
    val: DATA_MODEL_TABLE.dateOfNextPeriodicReview.value,
    order: 22,
    show: false,
    sortStatus: false
  },
  {
    key: DATA_MODEL_TABLE.maintenancePlan.key,
    val: DATA_MODEL_TABLE.maintenancePlan.value,
    order: 23,
    show: false,
    sortStatus: false
  },
  {
    key: DATA_MODEL_TABLE.gxpRelevant.key,
    val: DATA_MODEL_TABLE.gxpRelevant.value,
    order: 24,
    show: false,
    sortStatus: false
  },
  {
    key: DATA_MODEL_TABLE.qualificationStatus.key,
    val: DATA_MODEL_TABLE.qualificationStatus.value,
    order: 25,
    show: false,
    sortStatus: false
  },
  {
    key: DATA_MODEL_TABLE.dateOfNextMaintanance.key,
    val: DATA_MODEL_TABLE.dateOfNextMaintanance.value,
    order: 26,
    show: false,
    sortStatus: false
  },
  {
    key: DATA_MODEL_TABLE.softwareVersion.key,
    val: "Version",
    order: 27,
    show: false,
    sortStatus: false
  },
  {
    key: DATA_MODEL_TABLE.configurationBaseline.key,
    val: DATA_MODEL_TABLE.configurationBaseline.value,
    order: 28,
    show: false,
    sortStatus: false
  },
  {
    key: DATA_MODEL_TABLE.installedTests.key,
    val: DATA_MODEL_TABLE.installedTests.value,
    order: 29,
    show: false,
    sortStatus: false
  },
  {
    key: DATA_MODEL_TABLE.qualificationDocuments.key,
    val: DATA_MODEL_TABLE.qualificationDocuments.value,
    order: 30,
    show: false,
    sortStatus: false
  },
  {
    key: DATA_MODEL_TABLE.isVisualized.key,
    val: DATA_MODEL_TABLE.isVisualized.value,
    order: 31,
    show: false,
    sortStatus: false
  },
  {
    key: DATA_MODEL_TABLE.dateOfLastMaintanance.key,
    val: DATA_MODEL_TABLE.dateOfLastMaintanance.value,
    order: 32,
    show: false,
    sortStatus: false
  },
  {
    key: DATA_MODEL_TABLE.buildingLocation.key,
    val: DATA_MODEL_TABLE.buildingLocation.value,
    order: 33,
    show: false,
    sortStatus: false
  },
  {
    key: DATA_MODEL_TABLE.floor.key,
    val: DATA_MODEL_TABLE.floor.value,
    order: 34,
    show: false,
    sortStatus: false
  },
  {
    key: DATA_MODEL_TABLE.cluster.key,
    val: DATA_MODEL_TABLE.cluster.value,
    order: 35,
    show: false,
    sortStatus: false
  },
  {
    key: DATA_MODEL_TABLE.equipmentAdministrator.key,
    val: DATA_MODEL_TABLE.equipmentAdministrator.value,
    order: 36,
    show: false,
    sortStatus: false
  },
  {
    key: DATA_MODEL_TABLE.equipmentNickName.key,
    val: DATA_MODEL_TABLE.equipmentNickName.value,
    order: 37,
    show: false,
    sortStatus: false
  },
  {
    key: DATA_MODEL_TABLE.functionalLocation.key,
    val: DATA_MODEL_TABLE.functionalLocation.value,
    order: 38,
    show: false,
    sortStatus: false
  },
  {
    key: DATA_MODEL_TABLE.costCenter.key,
    val: DATA_MODEL_TABLE.costCenter.value,
    order: 39,
    show: false,
    sortStatus: false
  },
  {
    key: DATA_MODEL_TABLE.inventoryNumber.key,
    val: DATA_MODEL_TABLE.inventoryNumber.value,
    order: 40,
    show: false,
    sortStatus: false
  },
  {
    key: DATA_MODEL_TABLE.variant.key,
    val: DATA_MODEL_TABLE.variant.value,
    order: 41,
    show: false,
    sortStatus: false
  },
  {
    key: DATA_MODEL_TABLE.documentedMaintenanceDate.key,
    val: DATA_MODEL_TABLE.documentedMaintenanceDate.value,
    order: 42,
    show: false,
    sortStatus: false
  },
  {
    key: DATA_MODEL_TABLE.controlledEquipmentStatus.key,
    val: DATA_MODEL_TABLE.controlledEquipmentStatus.value,
    order: 43,
    show: false,
    sortStatus: false
  },
  {
    key: DATA_MODEL_TABLE.maintenanceIntervalInDays.key,
    val: DATA_MODEL_TABLE.maintenanceIntervalInDays.value,
    order: 44,
    show: false,
    sortStatus: false
  },
  {
    key: DATA_MODEL_TABLE.weightInkg.key,
    val: DATA_MODEL_TABLE.weightInkg.value,
    order: 45,
    show: false,
    sortStatus: false
  },
  {
    key: DATA_MODEL_TABLE.heightInmm.key,
    val: DATA_MODEL_TABLE.heightInmm.value,
    order: 46,
    show: false,
    sortStatus: false
  },
  {
    key: DATA_MODEL_TABLE.lengthInmm.key,
    val: DATA_MODEL_TABLE.lengthInmm.value,
    order: 47,
    show: false,
    sortStatus: false
  },
  {
    key: DATA_MODEL_TABLE.depthInmm.key,
    val: DATA_MODEL_TABLE.depthInmm.value,
    order: 48,
    show: false,
    sortStatus: false
  },
  {
    key: DATA_MODEL_TABLE.clearanceLeftInmm.key,
    val: DATA_MODEL_TABLE.clearanceLeftInmm.value,
    order: 49,
    show: false,
    sortStatus: false
  },
  {
    key: DATA_MODEL_TABLE.clearanceRightInmm.key,
    val: DATA_MODEL_TABLE.clearanceRightInmm.value,
    order: 50,
    show: false,
    sortStatus: false
  },
  {
    key: DATA_MODEL_TABLE.clearanceHeightInmm.key,
    val: DATA_MODEL_TABLE.clearanceHeightInmm.value,
    order: 51,
    show: false,
    sortStatus: false
  },
  {
    key: DATA_MODEL_TABLE.clearanceToFrontInmm.key,
    val: DATA_MODEL_TABLE.clearanceToFrontInmm.value,
    order: 52,
    show: false,
    sortStatus: false
  },
  {
    key: DATA_MODEL_TABLE.clearanceBackwardsInmm.key,
    val: DATA_MODEL_TABLE.clearanceBackwardsInmm.value,
    order: 53,
    show: false,
    sortStatus: false
  },
  {
    key: DATA_MODEL_TABLE.tags.key,
    val: DATA_MODEL_TABLE.tags.value,
    order: 54,
    show: false,
    sortStatus: false
  },
  {
    key: DATA_MODEL_TABLE.linkedInstance.key,
    val: DATA_MODEL_TABLE.linkedInstance.value,
    order: 55,
    show: false,
    sortStatus: false
  },
  {
    key: DATA_MODEL_TABLE.systemStatus.key,
    val: DATA_MODEL_TABLE.systemStatus.value,
    order: 56,
    show: false,
    sortStatus: false
  },
  {
    key: DATA_MODEL_TABLE.reviewers.key,
    val: DATA_MODEL_TABLE.reviewers.value,
    order: 57,
    show: false,
    sortStatus: false
  }
];
