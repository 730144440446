import { getAllData } from "./fetching";
import { LIST_DIGITAL_LAB_INSTRUMENT_REPOSITORY_USERS, LIST_IR_GROUP_ADMIN_MAPPINGS } from "../../gql/landingapi";
import { sortBy, isEqual } from "lodash";
import { extractReviewersIds } from "./dataMappers";
import omitDeep from "omit-deep-lodash";

/**
 * List of sites that we apply 4EP for.
 * @type {string[]}
 */
const ACCEPTABLE_SITES = ["Ludwigsburg", "Mannheim", "Penzberg"];

/** List of fields that do not trigger 4EP.
 * @type {string[]}
 */
const NEUTRAL_FIELDS = [
  "equipmentNickName",
  "tags",
  "comment",
  "network",
  "dateOfLastMaintanance",
  "dateOfNextMaintanance",
  "installedTests"
];

/**
 * Helper function to check if equipment data fulfills conditions for 4EP for new equipments.
 * @param belongingToGroup
 * @param siteName
 * @return {*|boolean}
 */
export const isNewData4eye = ({ belongingToGroup, siteName }) => {
  return belongingToGroup?.startsWith("DSR") && ACCEPTABLE_SITES.includes(siteName);
};

/**
 * Helper function to check if current and original equipment data fulfills conditions for 4EP for existing equipments
 * @param originalValues
 * @param currentValues
 * @return {*|boolean|boolean}
 */
export const isData4eye = (originalValues = {}, currentValues = {}) => {
  const fieldsToIgnore = ["isActive", "editReason", "configText", "editComment", "reviewers", ...NEUTRAL_FIELDS];
  return !isEqual(omitDeep(originalValues, fieldsToIgnore), omitDeep(currentValues, fieldsToIgnore))
    && (
      originalValues.belongingToGroup?.startsWith("DSR") &&
      ACCEPTABLE_SITES.includes(originalValues.siteName) &&
      (originalValues.gxpRelevant?.value ?? originalValues.gxpRelevant)?.toLowerCase?.() === "yes"
    );
};

/**
 * Helper function to check if equipment id is specific to newly created, not-yet approved (fake) equipments.
 * @param id
 * @return {*}
 */
export const isId4eye = (id) => {
  return id?.startsWith?.("4epf_");
};

/**
 * Helper function to prepare a list of reviewers for all 4EP processes like adding, editing and removing equipments.
 * @param client
 * @param equipmentGroup
 * @param currentUser
 * @return {Promise<{}[]>}
 */
export const getReviewers = async (client, equipmentGroup, currentUser) => {
  const [{ items: userItems }, ...groupsResults] = await Promise.all([
    getAllData({
      client,
      query: LIST_DIGITAL_LAB_INSTRUMENT_REPOSITORY_USERS,
      fetchPolicy: "network-only",
      dataPath: ["data", "listDigitalLabInstrumentRepositoryUsers"],
      drillData: true,
      variables: {
        limit: 1000
      }
    }),
    ...equipmentGroup.split("").map((character, index, charactersArray) => {
      return getAllData({
        client,
        query: LIST_IR_GROUP_ADMIN_MAPPINGS,
        fetchPolicy: "network-only",
        dataPath: ["data", "listIRGroupAdminMappings"],
        drillData: true,
        variables: {
          limit: 1000,
          groupName: {
            eq: charactersArray.slice(0, index + 1).join("")
          }
        }
      });
    })
  ]);
  const groupsReviewersIds = extractReviewersIds(groupsResults);

  let list = userItems
    .filter((item) => item?.userId !== currentUser?.user && groupsReviewersIds.includes(item?.userId))
    .map((item) => ({
      ...item,
      key: item?.email,
      value:
        item?.givenName && item?.familyName
          ? item?.name
            ? `${item?.givenName} ${item?.familyName} - ${item?.name}`
            : `${item?.givenName} ${item?.familyName}`
          : item.email
    }));
  return sortBy(list, "value");
};
