import gql from "graphql-tag";

export const GET_DIGITAL_LAB_INSTRUMENT_REPOSITORY_ENTRY = gql`
  query GetDigitalLabInstrumentRepositoryEntry($id: ID!) {
    getDigitalLabInstrumentRepositoryEntry(id: $id) {
      belongingToGroup
      buildingLocation {
        key
        value
      }
      checkInGracePeriod
      checkInRequired
      checkInStartPeriod
      cluster
      clusterId
      entryType
      comment
      configurationBaseline
      configurationDetails
      costCenter
      csv
      dateOfLastMaintanance
      dateOfNextMaintanance
      dateOfNextPeriodicReview
      documentedMaintenanceDate
      displayImage
      electronicRecord
      electronicSignatures
      equipmentAdministrator
      equipmentCategory
      equipmentId
      equipmentModel
      equipmentNickName
      floor {
        key
        value
      }
      functionalLocation
      gxpRelevant
      installedTests {
        name
        version
      }
      instrumentGTIN
      instrumentRUDI
      id
      inventoryNumber
      isBookable
      isVisualized
      linkedInstance
      location
      maintenanceIntervalInDays
      maintenancePlan
      manufacturer
      materialNumber
      modelName
      network
      qualificationDocuments {
        isSynchronized
        value {
          documentId
          name
        }
      }
      qualificationStatus
      controlledEquipmentStatus
      responsiblePerson
      responsibleProxy
      room {
        key
        value
      }
      secondaryID
      secondaryIDMapping
      serialNumber
      siteId
      siteName
      siteTimezone
      softwareVersion
      sop {
        key
        value
      }
      status
      systemOwner
      systemStatus
      tags
      variant
      linkedInstanceRef {
        isActive
        linkedInstance
        linkedInstanceDisplay
        secondaryIdMapping
      }
      trackPositionInCluster
      subEquipment {
        items {
          id
          belongingToGroup
          buildingLocation {
            key
            value
          }
          checkInGracePeriod
          checkInRequired
          checkInStartPeriod
          cluster
          clusterId
          comment
          configurationBaseline
          costCenter
          csv
          dateOfLastMaintanance
          dateOfNextMaintanance
          dateOfNextPeriodicReview
          documentedMaintenanceDate
          displayImage
          electronicRecord
          electronicSignatures
          entryType
          equipmentAdministrator
          equipmentCategory
          equipmentId
          equipmentModel
          equipmentNickName
          floor {
            key
            value
          }
          functionalLocation
          gxpRelevant
          installedTests {
            name
            version
          }
          instrumentGTIN
          instrumentRUDI
          inventoryNumber
          isBookable
          isClusterSWVersion
          isVisualized
          linkedInstance
          location
          maintenanceIntervalInDays
          maintenancePlan
          manufacturer
          materialNumber
          modelName
          network
          positionInCluster
          qualificationDocuments {
            isSynchronized
            value {
              documentId
              name
            }
          }
          qualificationStatus
          controlledEquipmentStatus
          responsiblePerson
          responsibleProxy
          room {
            key
            value
          }
          secondaryID
          secondaryIDMapping
          serialNumber
          siteId
          siteName
          siteTimezone
          softwareVersion
          sop {
            key
            value
          }
          status
          systemOwner
          systemStatus
          variant
          linkedInstanceRef {
            isActive
            linkedInstance
            secondaryIdMapping
          }
        }
      }
    }
  }
`;

export const LIST_DIGITAL_LAB_INSTRUMENT_REPOSITORY_ENTRYS = gql`
  query listDigitalLabInstrumentRepositoryEntrys(
    $limit: Int
    $nextToken: String
    $filter: ModelDigitalLabInstrumentRepositoryEntryFilterInput
    $subFilter: ModelDigitalLabInstrumentRepositoryEntryFilterInput
  ) {
    listDigitalLabInstrumentRepositoryEntrys(limit: $limit, nextToken: $nextToken, filter: $filter) {
      items {
        id
        belongingToGroup
        buildingLocation {
          key
          value
        }
        checkInGracePeriod
        checkInRequired
        checkInStartPeriod
        cluster
        clusterId
        comment
        configurationBaseline
        costCenter
        csv
        dateOfLastMaintanance
        dateOfNextMaintanance
        dateOfNextPeriodicReview
        documentedMaintenanceDate
        displayImage
        electronicRecord
        electronicSignatures
        entryType
        equipmentAdministrator
        equipmentCategory
        equipmentId
        equipmentModel
        equipmentNickName
        floor {
          key
          value
        }
        functionalLocation
        gxpRelevant
        installedTests {
          name
          version
        }
        instrumentGTIN
        instrumentRUDI
        inventoryNumber
        isBookable
        isVisualized
        linkedInstance
        location
        maintenanceIntervalInDays
        maintenancePlan
        manufacturer
        materialNumber
        modelName
        network
        qualificationDocuments {
          isSynchronized
          value {
            documentId
            name
          }
        }
        qualificationStatus
        controlledEquipmentStatus
        responsiblePerson
        responsibleProxy
        room {
          key
          value
        }
        secondaryID
        secondaryIDMapping
        serialNumber
        siteId
        siteName
        siteTimezone
        softwareVersion
        sop {
          key
          value
        }
        status
        systemOwner
        systemStatus
        tags
        variant
        tags
        linkedInstanceRef {
          isActive
          linkedInstance
          secondaryIdMapping
        }
        trackPositionInCluster
        weightInkg
        heightInmm
        lengthInmm
        depthInmm
        clearanceLeftInmm
        clearanceRightInmm
        clearanceHeightInmm
        clearanceToFrontInmm
        clearanceBackwardsInmm
        heatOutputInW
        pressureEquipment
        noiseIndBA
        noiseSource
        noiseSourceDetails
        positioningNote
        positioning
        vibrationSensitive
        subEquipment(filter: $subFilter) {
          items {
            id
            belongingToGroup
            buildingLocation {
              key
              value
            }
            checkInGracePeriod
            checkInRequired
            checkInStartPeriod
            cluster
            clusterId
            comment
            configurationBaseline
            costCenter
            csv
            dateOfLastMaintanance
            dateOfNextMaintanance
            dateOfNextPeriodicReview
            documentedMaintenanceDate
            displayImage
            electronicRecord
            electronicSignatures
            entryType
            equipmentAdministrator
            equipmentCategory
            equipmentId
            equipmentModel
            equipmentNickName
            floor {
              key
              value
            }
            functionalLocation
            gxpRelevant
            installedTests {
              name
              version
            }
            instrumentGTIN
            instrumentRUDI
            inventoryNumber
            isBookable
            isVisualized
            linkedInstance
            location
            maintenanceIntervalInDays
            maintenancePlan
            manufacturer
            materialNumber
            modelName
            network
            positionInCluster
            qualificationDocuments {
              isSynchronized
              value {
                documentId
                name
              }
            }
            qualificationStatus
            controlledEquipmentStatus
            responsiblePerson
            responsibleProxy
            room {
              key
              value
            }
            secondaryID
            secondaryIDMapping
            serialNumber
            siteId
            siteName
            siteTimezone
            softwareVersion
            sop {
              key
              value
            }
            status
            systemOwner
            systemStatus
            tags
            variant
            tags
            linkedInstanceRef {
              isActive
              linkedInstance
              secondaryIdMapping
            }
            weightInkg
            heightInmm
            lengthInmm
            depthInmm
            clearanceLeftInmm
            clearanceRightInmm
            clearanceHeightInmm
            clearanceToFrontInmm
            clearanceBackwardsInmm
            heatOutputInW
            pressureEquipment
            noiseIndBA
            noiseSource
            noiseSourceDetails
            positioningNote
            positioning
            vibrationSensitive
          }
        }
      }

      nextToken
    }
  }
`;

export const LIST_EQUIPMENT_BY_MODEL_NAME_AND_BELONGING_TO_GROUP = gql`
  query listIREntryByModelNameAndBelongingToGroup(
    $limit: Int
    $nextToken: String
    $filter: ModelDigitalLabInstrumentRepositoryEntryFilterInput
    $subFilter: ModelDigitalLabInstrumentRepositoryEntryFilterInput
    $sortDirection: ModelSortDirection
    $belongingToGroup: ModelStringKeyConditionInput
  ) {
    listIREntryByModelNameAndBelongingToGroup(
      limit: $limit
      nextToken: $nextToken
      filter: $filter
      sortDirection: $sortDirection
      modelName: "IREquipment"
      belongingToGroup: $belongingToGroup
    ) {
      items {
        id
        belongingToGroup
        buildingLocation {
          key
          value
        }
        checkInGracePeriod
        checkInRequired
        checkInStartPeriod
        cluster
        clusterId
        comment
        configurationBaseline
        costCenter
        csv
        dateOfLastMaintanance
        dateOfNextMaintanance
        dateOfNextPeriodicReview
        documentedMaintenanceDate
        displayImage
        electronicRecord
        electronicSignatures
        entryType
        equipmentAdministrator
        equipmentCategory
        equipmentId
        equipmentModel
        equipmentNickName
        floor {
          key
          value
        }
        functionalLocation
        gxpRelevant
        installedTests {
          name
          version
        }
        instrumentGTIN
        instrumentRUDI

        inventoryNumber
        isBookable
        isVisualized
        linkedInstance
        location
        maintenanceIntervalInDays
        maintenancePlan
        manufacturer
        materialNumber
        modelName
        network
        qualificationDocuments {
          isSynchronized
          value {
            documentId
            name
          }
        }
        qualificationStatus
        controlledEquipmentStatus
        responsiblePerson
        responsibleProxy
        room {
          key
          value
        }
        secondaryID
        secondaryIDMapping
        serialNumber
        siteId
        siteName
        siteTimezone
        softwareVersion
        sop {
          key
          value
        }
        status
        systemOwner
        systemStatus
        tags
        variant
        linkedInstanceRef {
          isActive
          linkedInstance
          secondaryIdMapping
        }
        trackPositionInCluster
        weightInkg
        heightInmm
        lengthInmm
        depthInmm
        clearanceLeftInmm
        clearanceRightInmm
        clearanceHeightInmm
        clearanceToFrontInmm
        clearanceBackwardsInmm
        heatOutputInW
        pressureEquipment
        noiseIndBA
        noiseSource
        noiseSourceDetails
        positioningNote
        positioning
        vibrationSensitive
        subEquipment(sortBy: "belongingToGroup", sortDirection: $sortDirection, filter: $subFilter) {
          items {
            id
            belongingToGroup
            buildingLocation {
              key
              value
            }
            checkInGracePeriod
            checkInRequired
            checkInStartPeriod
            cluster
            clusterId
            comment
            configurationBaseline
            costCenter
            csv
            dateOfLastMaintanance
            dateOfNextMaintanance
            dateOfNextPeriodicReview
            documentedMaintenanceDate
            displayImage
            electronicRecord
            electronicSignatures
            entryType
            equipmentAdministrator
            equipmentCategory
            equipmentId
            equipmentModel
            equipmentNickName
            floor {
              key
              value
            }
            functionalLocation
            gxpRelevant
            installedTests {
              name
              version
            }
            instrumentGTIN
            instrumentRUDI
            inventoryNumber
            isBookable
            isVisualized
            linkedInstance
            location
            maintenanceIntervalInDays
            maintenancePlan
            manufacturer
            materialNumber
            modelName
            network
            positionInCluster
            qualificationDocuments {
              isSynchronized
              value {
                documentId
                name
              }
            }
            qualificationStatus
            controlledEquipmentStatus
            responsiblePerson
            responsibleProxy
            room {
              key
              value
            }
            secondaryID
            secondaryIDMapping
            serialNumber
            siteId
            siteName
            siteTimezone
            softwareVersion
            sop {
              key
              value
            }
            status
            systemOwner
            systemStatus
            tags
            variant
            linkedInstanceRef {
              isActive
              linkedInstance
              secondaryIdMapping
            }
            weightInkg
            heightInmm
            lengthInmm
            depthInmm
            clearanceLeftInmm
            clearanceRightInmm
            clearanceHeightInmm
            clearanceToFrontInmm
            clearanceBackwardsInmm
            heatOutputInW
            pressureEquipment
            noiseIndBA
            noiseSource
            noiseSourceDetails
            positioningNote
            positioning
            vibrationSensitive
          }
        }
      }
      nextToken
    }
  }
`;

export const LIST_EQUIPMENT_BY_MODEL_NAME_AND_RESPONSIBLE_PERSON = gql`
  query listIREntryByModelNameAndResponsiblePerson(
    $limit: Int
    $nextToken: String
    $filter: ModelDigitalLabInstrumentRepositoryEntryFilterInput
    $subFilter: ModelDigitalLabInstrumentRepositoryEntryFilterInput
    $sortDirection: ModelSortDirection
    $responsiblePerson: ModelStringKeyConditionInput
  ) {
    listIREntryByModelNameAndResponsiblePerson(
      limit: $limit
      nextToken: $nextToken
      filter: $filter
      sortDirection: $sortDirection
      modelName: "IREquipment"
      responsiblePerson: $responsiblePerson
    ) {
      items {
        id
        belongingToGroup
        buildingLocation {
          key
          value
        }
        checkInGracePeriod
        checkInRequired
        checkInStartPeriod
        cluster
        clusterId
        comment
        configurationBaseline
        costCenter
        csv
        dateOfLastMaintanance
        dateOfNextMaintanance
        dateOfNextPeriodicReview
        documentedMaintenanceDate
        displayImage
        electronicRecord
        electronicSignatures
        entryType
        equipmentAdministrator
        equipmentCategory
        equipmentId
        equipmentModel
        equipmentNickName
        floor {
          key
          value
        }
        functionalLocation
        gxpRelevant
        installedTests {
          name
          version
        }
        instrumentGTIN
        instrumentRUDI

        inventoryNumber
        isBookable
        isVisualized
        linkedInstance
        location
        maintenanceIntervalInDays
        maintenancePlan
        manufacturer
        materialNumber
        modelName
        network
        qualificationDocuments {
          isSynchronized
          value {
            documentId
            name
          }
        }
        qualificationStatus
        controlledEquipmentStatus
        responsiblePerson
        responsibleProxy
        room {
          key
          value
        }
        secondaryID
        secondaryIDMapping
        serialNumber
        siteId
        siteName
        siteTimezone
        softwareVersion
        sop {
          key
          value
        }
        status
        systemOwner
        systemStatus
        tags
        variant
        linkedInstanceRef {
          isActive
          linkedInstance
          secondaryIdMapping
        }
        trackPositionInCluster
        weightInkg
        heightInmm
        lengthInmm
        depthInmm
        clearanceLeftInmm
        clearanceRightInmm
        clearanceHeightInmm
        clearanceToFrontInmm
        clearanceBackwardsInmm
        heatOutputInW
        pressureEquipment
        noiseIndBA
        noiseSource
        noiseSourceDetails
        positioningNote
        positioning
        vibrationSensitive
        subEquipment(sortBy: "responsiblePerson", sortDirection: $sortDirection, filter: $subFilter) {
          items {
            id
            belongingToGroup
            buildingLocation {
              key
              value
            }
            checkInGracePeriod
            checkInRequired
            checkInStartPeriod
            cluster
            clusterId
            comment
            configurationBaseline
            costCenter
            csv
            dateOfLastMaintanance
            dateOfNextMaintanance
            dateOfNextPeriodicReview
            documentedMaintenanceDate
            displayImage
            electronicRecord
            electronicSignatures
            entryType
            equipmentAdministrator
            equipmentCategory
            equipmentId
            equipmentModel
            equipmentNickName
            floor {
              key
              value
            }
            functionalLocation
            gxpRelevant
            installedTests {
              name
              version
            }
            instrumentGTIN
            instrumentRUDI
            inventoryNumber
            isBookable
            isVisualized
            linkedInstance
            location
            maintenanceIntervalInDays
            maintenancePlan
            manufacturer
            materialNumber
            modelName
            network
            positionInCluster
            qualificationDocuments {
              isSynchronized
              value {
                documentId
                name
              }
            }
            qualificationStatus
            controlledEquipmentStatus
            responsiblePerson
            responsibleProxy
            room {
              key
              value
            }
            secondaryID
            secondaryIDMapping
            serialNumber
            siteId
            siteName
            siteTimezone
            softwareVersion
            sop {
              key
              value
            }
            status
            systemOwner
            systemStatus
            tags
            variant
            linkedInstanceRef {
              isActive
              linkedInstance
              secondaryIdMapping
            }
            weightInkg
            heightInmm
            lengthInmm
            depthInmm
            clearanceLeftInmm
            clearanceRightInmm
            clearanceHeightInmm
            clearanceToFrontInmm
            clearanceBackwardsInmm
            heatOutputInW
            pressureEquipment
            noiseIndBA
            noiseSource
            noiseSourceDetails
            positioningNote
            positioning
            vibrationSensitive
          }
        }
      }
      nextToken
    }
  }
`;

export const LIST_EQUIPMENT_BY_MODEL_NAME_AND_EQUIPMENT_MODEL = gql`
  query listIREntryByModelNameAndEquipmentModel(
    $limit: Int
    $nextToken: String
    $filter: ModelDigitalLabInstrumentRepositoryEntryFilterInput
    $subFilter: ModelDigitalLabInstrumentRepositoryEntryFilterInput
    $sortDirection: ModelSortDirection
    $equipmentModel: ModelStringKeyConditionInput
  ) {
    listIREntryByModelNameAndEquipmentModel(
      limit: $limit
      nextToken: $nextToken
      filter: $filter
      sortDirection: $sortDirection
      modelName: "IREquipment"
      equipmentModel: $equipmentModel
    ) {
      items {
        id
        belongingToGroup
        buildingLocation {
          key
          value
        }
        checkInGracePeriod
        checkInRequired
        checkInStartPeriod
        cluster
        clusterId
        comment
        configurationBaseline
        costCenter
        csv
        dateOfLastMaintanance
        dateOfNextMaintanance
        dateOfNextPeriodicReview
        documentedMaintenanceDate
        displayImage
        electronicRecord
        electronicSignatures
        entryType
        equipmentAdministrator
        equipmentCategory
        equipmentId
        equipmentModel
        equipmentNickName
        floor {
          key
          value
        }
        functionalLocation
        gxpRelevant
        installedTests {
          name
          version
        }
        instrumentGTIN
        instrumentRUDI

        inventoryNumber
        isBookable
        isVisualized
        linkedInstance
        location
        maintenanceIntervalInDays
        maintenancePlan
        manufacturer
        materialNumber
        modelName
        network
        qualificationDocuments {
          isSynchronized
          value {
            documentId
            name
          }
        }
        qualificationStatus
        controlledEquipmentStatus
        responsiblePerson
        responsibleProxy
        room {
          key
          value
        }
        secondaryID
        secondaryIDMapping
        serialNumber
        siteId
        siteName
        siteTimezone
        softwareVersion
        sop {
          key
          value
        }
        status
        systemOwner
        systemStatus
        tags
        variant
        linkedInstanceRef {
          isActive
          linkedInstance
          secondaryIdMapping
        }
        trackPositionInCluster
        weightInkg
        heightInmm
        lengthInmm
        depthInmm
        clearanceLeftInmm
        clearanceRightInmm
        clearanceHeightInmm
        clearanceToFrontInmm
        clearanceBackwardsInmm
        heatOutputInW
        pressureEquipment
        noiseIndBA
        noiseSource
        noiseSourceDetails
        positioningNote
        positioning
        vibrationSensitive
        subEquipment(sortBy: "equipmentModel", sortDirection: $sortDirection, filter: $subFilter) {
          items {
            id
            belongingToGroup
            buildingLocation {
              key
              value
            }
            checkInGracePeriod
            checkInRequired
            checkInStartPeriod
            cluster
            clusterId
            comment
            configurationBaseline
            costCenter
            csv
            dateOfLastMaintanance
            dateOfNextMaintanance
            dateOfNextPeriodicReview
            documentedMaintenanceDate
            displayImage
            electronicRecord
            electronicSignatures
            entryType
            equipmentAdministrator
            equipmentCategory
            equipmentId
            equipmentModel
            equipmentNickName
            floor {
              key
              value
            }
            functionalLocation
            gxpRelevant
            installedTests {
              name
              version
            }
            instrumentGTIN
            instrumentRUDI
            inventoryNumber
            isBookable
            isVisualized
            linkedInstance
            location
            maintenanceIntervalInDays
            maintenancePlan
            manufacturer
            materialNumber
            modelName
            network
            positionInCluster
            qualificationDocuments {
              isSynchronized
              value {
                documentId
                name
              }
            }
            qualificationStatus
            controlledEquipmentStatus
            responsiblePerson
            responsibleProxy
            room {
              key
              value
            }
            secondaryID
            secondaryIDMapping
            serialNumber
            siteId
            siteName
            siteTimezone
            softwareVersion
            sop {
              key
              value
            }
            status
            systemOwner
            systemStatus
            tags
            variant
            linkedInstanceRef {
              isActive
              linkedInstance
              secondaryIdMapping
            }
            weightInkg
            heightInmm
            lengthInmm
            depthInmm
            clearanceLeftInmm
            clearanceRightInmm
            clearanceHeightInmm
            clearanceToFrontInmm
            clearanceBackwardsInmm
            heatOutputInW
            pressureEquipment
            noiseIndBA
            noiseSource
            noiseSourceDetails
            positioningNote
            positioning
            vibrationSensitive
          }
        }
      }
      nextToken
    }
  }
`;

export const LIST_EQUIPMENT_BY_MODEL_NAME_AND_LOCATION = gql`
  query listIREntryByModelNameAndLocation(
    $limit: Int
    $nextToken: String
    $filter: ModelDigitalLabInstrumentRepositoryEntryFilterInput
    $subFilter: ModelDigitalLabInstrumentRepositoryEntryFilterInput
    $sortDirection: ModelSortDirection
    $location: ModelStringKeyConditionInput
  ) {
    listIREntryByModelNameAndLocation(
      limit: $limit
      nextToken: $nextToken
      filter: $filter
      sortDirection: $sortDirection
      modelName: "IREquipment"
      location: $location
    ) {
      items {
        id
        belongingToGroup
        buildingLocation {
          key
          value
        }
        checkInGracePeriod
        checkInRequired
        checkInStartPeriod
        cluster
        clusterId
        comment
        configurationBaseline
        costCenter
        csv
        dateOfLastMaintanance
        dateOfNextMaintanance
        dateOfNextPeriodicReview
        documentedMaintenanceDate
        displayImage
        electronicRecord
        electronicSignatures
        entryType
        equipmentAdministrator
        equipmentCategory
        equipmentId
        equipmentModel
        equipmentNickName
        floor {
          key
          value
        }
        functionalLocation
        gxpRelevant
        installedTests {
          name
          version
        }
        instrumentGTIN
        instrumentRUDI

        inventoryNumber
        isBookable
        isVisualized
        linkedInstance
        location
        maintenanceIntervalInDays
        maintenancePlan
        manufacturer
        materialNumber
        modelName
        network
        qualificationDocuments {
          isSynchronized
          value {
            documentId
            name
          }
        }
        qualificationStatus
        controlledEquipmentStatus
        responsiblePerson
        responsibleProxy
        room {
          key
          value
        }
        secondaryID
        secondaryIDMapping
        serialNumber
        siteId
        siteName
        siteTimezone
        softwareVersion
        sop {
          key
          value
        }
        status
        systemOwner
        systemStatus
        tags
        variant
        linkedInstanceRef {
          isActive
          linkedInstance
          secondaryIdMapping
        }
        trackPositionInCluster
        weightInkg
        heightInmm
        lengthInmm
        depthInmm
        clearanceLeftInmm
        clearanceRightInmm
        clearanceHeightInmm
        clearanceToFrontInmm
        clearanceBackwardsInmm
        subEquipment(sortBy: "location", sortDirection: $sortDirection, filter: $subFilter) {
          items {
            id
            belongingToGroup
            buildingLocation {
              key
              value
            }
            checkInGracePeriod
            checkInRequired
            checkInStartPeriod
            cluster
            clusterId
            comment
            configurationBaseline
            costCenter
            csv
            dateOfLastMaintanance
            dateOfNextMaintanance
            dateOfNextPeriodicReview
            documentedMaintenanceDate
            displayImage
            electronicRecord
            electronicSignatures
            entryType
            equipmentAdministrator
            equipmentCategory
            equipmentId
            equipmentModel
            equipmentNickName
            floor {
              key
              value
            }
            functionalLocation
            gxpRelevant
            installedTests {
              name
              version
            }
            instrumentGTIN
            instrumentRUDI
            inventoryNumber
            isBookable
            isVisualized
            linkedInstance
            location
            maintenanceIntervalInDays
            maintenancePlan
            manufacturer
            materialNumber
            modelName
            network
            positionInCluster
            qualificationDocuments {
              isSynchronized
              value {
                documentId
                name
              }
            }
            qualificationStatus
            controlledEquipmentStatus
            responsiblePerson
            responsibleProxy
            room {
              key
              value
            }
            secondaryID
            secondaryIDMapping
            serialNumber
            siteId
            siteName
            siteTimezone
            softwareVersion
            sop {
              key
              value
            }
            status
            systemOwner
            systemStatus
            tags
            variant
            linkedInstanceRef {
              isActive
              linkedInstance
              secondaryIdMapping
            }
            weightInkg
            heightInmm
            lengthInmm
            depthInmm
            clearanceLeftInmm
            clearanceRightInmm
            clearanceHeightInmm
            clearanceToFrontInmm
            clearanceBackwardsInmm
          }
        }
      }
      nextToken
    }
  }
`;

export const LIST_EQUIPMENT_BY_MODEL_NAME_AND_SERIAL_NUMBER = gql`
  query listIREntryByModelNameAndSerialNumber(
    $limit: Int
    $nextToken: String
    $filter: ModelDigitalLabInstrumentRepositoryEntryFilterInput
    $subFilter: ModelDigitalLabInstrumentRepositoryEntryFilterInput
    $sortDirection: ModelSortDirection
    $serialNumber: ModelStringKeyConditionInput
  ) {
    listIREntryByModelNameAndSerialNumber(
      limit: $limit
      nextToken: $nextToken
      filter: $filter
      sortDirection: $sortDirection
      modelName: "IREquipment"
      serialNumber: $serialNumber
    ) {
      items {
        id
        belongingToGroup
        buildingLocation {
          key
          value
        }
        checkInGracePeriod
        checkInRequired
        checkInStartPeriod
        cluster
        clusterId
        comment
        configurationBaseline
        costCenter
        csv
        dateOfLastMaintanance
        dateOfNextMaintanance
        dateOfNextPeriodicReview
        documentedMaintenanceDate
        displayImage
        electronicRecord
        electronicSignatures
        entryType
        equipmentAdministrator
        equipmentCategory
        equipmentId
        equipmentModel
        equipmentNickName
        floor {
          key
          value
        }
        functionalLocation
        gxpRelevant
        installedTests {
          name
          version
        }
        instrumentGTIN
        instrumentRUDI

        inventoryNumber
        isBookable
        isVisualized
        linkedInstance
        location
        maintenanceIntervalInDays
        maintenancePlan
        manufacturer
        materialNumber
        modelName
        network
        qualificationDocuments {
          isSynchronized
          value {
            documentId
            name
          }
        }
        qualificationStatus
        controlledEquipmentStatus
        responsiblePerson
        responsibleProxy
        room {
          key
          value
        }
        secondaryID
        secondaryIDMapping
        serialNumber
        siteId
        siteName
        siteTimezone
        softwareVersion
        sop {
          key
          value
        }
        status
        systemOwner
        systemStatus
        tags
        variant
        linkedInstanceRef {
          isActive
          linkedInstance
          secondaryIdMapping
        }
        trackPositionInCluster
        weightInkg
        heightInmm
        lengthInmm
        depthInmm
        clearanceLeftInmm
        clearanceRightInmm
        clearanceHeightInmm
        clearanceToFrontInmm
        clearanceBackwardsInmm
        heatOutputInW
        pressureEquipment
        noiseIndBA
        noiseSource
        noiseSourceDetails
        positioningNote
        positioning
        vibrationSensitive
        subEquipment(sortBy: "serialNumber", sortDirection: $sortDirection, filter: $subFilter) {
          items {
            id
            belongingToGroup
            buildingLocation {
              key
              value
            }
            checkInGracePeriod
            checkInRequired
            checkInStartPeriod
            cluster
            clusterId
            comment
            configurationBaseline
            costCenter
            csv
            dateOfLastMaintanance
            dateOfNextMaintanance
            dateOfNextPeriodicReview
            documentedMaintenanceDate
            displayImage
            electronicRecord
            electronicSignatures
            entryType
            equipmentAdministrator
            equipmentCategory
            equipmentId
            equipmentModel
            equipmentNickName
            floor {
              key
              value
            }
            functionalLocation
            gxpRelevant
            installedTests {
              name
              version
            }
            instrumentGTIN
            instrumentRUDI
            inventoryNumber
            isBookable
            isVisualized
            linkedInstance
            location
            maintenanceIntervalInDays
            maintenancePlan
            manufacturer
            materialNumber
            modelName
            network
            positionInCluster
            qualificationDocuments {
              isSynchronized
              value {
                documentId
                name
              }
            }
            qualificationStatus
            controlledEquipmentStatus
            responsiblePerson
            responsibleProxy
            room {
              key
              value
            }
            secondaryID
            secondaryIDMapping
            serialNumber
            siteId
            siteName
            siteTimezone
            softwareVersion
            sop {
              key
              value
            }
            status
            systemOwner
            systemStatus
            tags
            variant
            linkedInstanceRef {
              isActive
              linkedInstance
              secondaryIdMapping
            }
            weightInkg
            heightInmm
            lengthInmm
            depthInmm
            clearanceLeftInmm
            clearanceRightInmm
            clearanceHeightInmm
            clearanceToFrontInmm
            clearanceBackwardsInmm
            heatOutputInW
            pressureEquipment
            noiseIndBA
            noiseSource
            noiseSourceDetails
            positioningNote
            positioning
            vibrationSensitive
          }
        }
      }
      nextToken
    }
  }
`;

export const LIST_IR_ENTRY_UNICITY_CHECK = gql`
  query listIREntryByLinkedInstanceAndSecondaryID($linkedInstance: String, $secondaryID: String, $nextToken: String) {
    listIREntryByLinkedInstanceAndSecondaryID(
      linkedInstance: $linkedInstance
      secondaryID: { eq: $secondaryID }
      nextToken: $nextToken
    ) {
      items {
        id
      }
      nextToken
    }
  }
`;

export const LIST_IR_ENTRY_NONE_UNICITY_CHECK = gql`
  query listIREntryByManufacturerModelAndSNo(
    $manufacturer: String
    $equipmentModel: String
    $serialNumber: String
    $nextToken: String
  ) {
    listIREntryByManufacturerModelAndSNo(
      modelName: "IREquipment"
      manufacturerEquipmentModelSerialNumber: {
        eq: { equipmentModel: $equipmentModel, manufacturer: $manufacturer, serialNumber: $serialNumber }
      }
      nextToken: $nextToken
    ) {
      items {
        id
      }
      nextToken
    }
  }
`;

export const GET_INSTRUMENT_FROM_DATA_RIVER = gql`
  query getInstrumentFromDataRiver($materialNumber: String!, $serialNumber: String!) {
    getInstrumentFromDataRiver(materialNumber: $materialNumber, serialNumber: $serialNumber) {
      materialNumber
      serialNumber
      systemStatus
      responsiblePerson
      buildingLocation {
        key
        value
      }
      costCenter
      dateOfLastMaintanance
      dateOfNextMaintanance
      equipmentModel
      manufacturer
      equipmentId
      equipmentNickName
      instrumentGTIN
      instrumentRUDI
      inventoryNumber
      gxpRelevant
      qualificationDocuments {
        isSynchronized
        value {
          documentId
          name
        }
      }
      siteId
      floor {
        key
        value
      }
      room {
        key
        value
      }
      configurationBaseline
    }
  }
`;

export const LIST_BACKEND_HEALTH_CHECKS = gql`
  query listBackendHealthChecks {
    listBackendHealthChecks {
      items {
        ciJobId
        commitHash
        id
        moduleName
        enableAnalyticsTracking
        moduleVersion
        updatedAt
        isAppUnderMaintenance
      }
    }
  }
`;

export const LIST_SITES = gql`
  query listSites($limit: Int, $nextToken: String) {
    listSites(limit: $limit, nextToken: $nextToken) {
      items {
        siteName
        siteId
        timeZone
      }
      nextToken
    }
  }
`;
export const GET_LOGGED_USER_DETAILS = gql`
  query getLoggedInUserDetails {
    getLoggedInUserDetails(limit: 1) {
      items {
        id
        email
        lastFilter
        lastDisplayColumns
        familyName
        givenName
        name
        phone
        status
        site
        user
        lastPageSize
        searchPreference
      }
    }
  }
`;

export const GET_IR_BUILDING = gql`
  query getIRBuildingMaster($id: Int!) {
    getIRBuildingMaster(id: $id, modelName: "IRBuildingMaster") {
      id
      buildingNumber
      siteId
      isActive
    }
  }
`;

export const GET_IR_FLOOR = gql`
  query getIRFloorMaster($id: Int!) {
    getIRFloorMaster(id: $id, modelName: "IRFloorMaster") {
      id
      buildingsId
      floorCode
      siteId
      isActive
    }
  }
`;

export const GET_IR_ROOM = gql`
  query getIRRoomMaster($id: Int!) {
    getIRRoomMaster(id: $id, modelName: "IRRoomMaster") {
      id
      roomCode
      siteId
      isActive
    }
  }
`;

export const LIST_IR_BUILDING_MASTER = gql`
  query listBuildingBySiteIdAndBuildingNumber($limit: Int, $nextToken: String, $siteId: String) {
    listBuildingBySiteIdAndBuildingNumber(
      filter: { isActive: { eq: true } }
      limit: $limit
      nextToken: $nextToken
      siteId: $siteId
    ) {
      nextToken
      items {
        buildingNumber
        siteId
        id
      }
    }
  }
`;

export const LIST_IR_FLOOR_MASTER = gql`
  query listFloorBySiteIdAndFloorCode($limit: Int, $nextToken: String, $siteId: String, $id: Int) {
    listFloorBySiteIdAndFloorCode(
      filter: { isActive: { eq: true }, buildingsId: { eq: $id } }
      limit: $limit
      nextToken: $nextToken
      siteId: $siteId
    ) {
      nextToken
      items {
        buildingsId
        floorCode
        siteId
        id
      }
    }
  }
`;

export const LIST_IR_ROOM_MASTER = gql`
  query listRoomBySiteIdAndRoomCode($limit: Int, $nextToken: String, $siteId: String, $id: Int) {
    listRoomBySiteIdAndRoomCode(
      filter: { isActive: { eq: true }, floorsId: { eq: $id } }
      limit: $limit
      nextToken: $nextToken
      siteId: $siteId
    ) {
      nextToken
      items {
        roomCode
        siteId
        id
      }
    }
  }
`;

export const LIST_IR_GROUP_MASTER = gql`
  query listIRGroupMasters($limit: Int, $nextToken: String) {
    listIRGroupMasters(limit: $limit, nextToken: $nextToken) {
      nextToken
      items {
        value
      }
    }
  }
`;

export const LIST_IR_SOP_MASTER = gql`
  query listIRSOPMasters($limit: Int, $nextToken: String) {
    listIRSOPMasters(limit: $limit, nextToken: $nextToken, filter: { isActive: { eq: true } }) {
      nextToken
      items {
        isActive
        key
        value
      }
    }
  }
`;

export const LIST_IR_EDITREASON_MASTER = gql`
  query listIRConfigurableMasters($limit: Int, $nextToken: String) {
    listIRConfigurableMasters(limit: $limit, nextToken: $nextToken) {
      nextToken
      items {
        modelName
        configText
      }
    }
  }
`;

export const LIST_IR_CATEGORY_MAPPINGS_MASTER = gql`
  query listIRCategoryMappings($limit: Int, $nextToken: String) {
    listIRCategoryMappings(limit: $limit, nextToken: $nextToken, filter: { isActive: { eq: true } }) {
      nextToken
      items {
        displayImage
        equipmentCategory
        equipmentModel
        isActive
        manufacturer
        modelName
        variant
      }
    }
  }
`;

export const LIST_IR_CLUSTER_CATEGORY_MAPPINGS_MASTER = gql`
  query listIRClusterCategoryMappings($limit: Int, $nextToken: String) {
    listIRClusterCategoryMappings(limit: $limit, nextToken: $nextToken, filter: { isActive: { eq: true } }) {
      nextToken
      items {
        equipmentCategory: clusterCategory
        equipmentModel: clusterModel
        isActive
        variant
        modifiedById
        modelName
      }
    }
  }
`;

export const LIST_IR_LINKED_INSTANCE_MASTER = gql`
  query listSupportedLinkedInstances($limit: Int, $nextToken: String) {
    listSupportedLinkedInstances(limit: $limit, nextToken: $nextToken, filter: { isActive: { eq: true } }) {
      nextToken
      items {
        linkedInstance
        linkedInstanceDisplay
        secondaryIdMapping
      }
    }
  }
`;

export const LIST_IR_FILTER_LOOKUPS_MASTER = gql`
  query listIRFilterLookups($limit: Int, $nextToken: String, $field: String) {
    listIRFilterLookups(limit: $limit, nextToken: $nextToken, filterAttribute: $field, sortDirection: ASC) {
      nextToken
      items {
        filterValue
      }
    }
  }
`;

export const LIST_COMBINED_RESULT = gql`
  query listCombinedResult($limit: Int, $field: String) {
    listIRBuildingMasters(limit: $limit) {
      items {
        isActive
        key
        value
      }
    }

    listIRConfigurableMasters(limit: $limit) {
      items {
        modelName
        configText
      }
    }

    listIRGroupMasters(limit: $limit) {
      items {
        value
      }
    }

    listIRSOPMasters(limit: $limit) {
      items {
        isActive
        key
        value
      }
    }

    listIRFilterLookups(filterAttribute: $field) {
      items {
        filterValue
      }
    }
  }
`;

export const LIST_IR_GROUP_ADMIN_MAPPINGS = gql`
  query listIRGroupAdminMappings(
    $limit: Int
    $nextToken: String
    $filter: ModelIRGroupAdminMappingFilterInput
    $groupName: ModelStringKeyConditionInput
  ) {
    listIRGroupAdminMappings(
      limit: $limit
      nextToken: $nextToken
      filter: $filter
      modelName: "IRGroupAdminMapping"
      groupName: $groupName
    ) {
      items {
        groupName
        userIds {
          email
          familyName
          givenName
          name
          userId
        }
        reviewers {
          email
          familyName
          givenName
          name
          userId
        }
      }
      nextToken
    }
  }
`;

export const UNICITY_CHECK_LIST_IR_GROUP_ADMIN_MAPPINGS = gql`
  query listIRGroupAdminMappings($filter: String) {
    listIRGroupAdminMappings(modelName: "IRGroupAdminMapping", groupName: { eq: $filter }) {
      items {
        groupName
      }
    }
  }
`;

export const LIST_DIGITAL_LAB_INSTRUMENT_REPOSITORY_USERS = gql`
  query listDigitalLabInstrumentRepositoryUsers($limit: Int, $nextToken: String) {
    listDigitalLabInstrumentRepositoryUsers(limit: $limit, nextToken: $nextToken) {
      items {
        email
        familyName
        givenName
        name
        userId
      }
      nextToken
    }
  }
`;

export const LIST_INSTRUMENT_REPOSITORY_AUDITS = gql`
  query listInstrumentRepositoryAudits($inventoryId: String) {
    listInstrumentRepositoryAudits(inventoryId: $inventoryId, sortDirection: DESC) {
      items {
        action
        changes
        editReason
        editComment
        equipmentCategory
        equipmentId
        equipmentModel
        equipmentNickName
        inventoryId
        linkedInstanceRef {
          isActive
          linkedInstance
          secondaryIdMapping
        }
        location
        manufacturer
        materialNumber
        modifiedByEmail
        modifiedByUserId
        modifiedDate
        newData
        oldData
        secondaryId
        secondaryIdMapping
        serialNumber
        updatedAt
        variant
        belongingToGroup
        responsiblePerson
        responsibleProxy
        siteName
        proposedChanges {
          changes
          id
          inventoryId
          newData
          reviewStatus
          reviewers
          createdBy {
            items {
              email
              name
              userId
            }
          }
        }
        proposedChangesId
      }
    }
  }
`;

export const LIST_DIGITAL_LAB_INSTRUMENT_USER_FILTER = gql`
  query getLoggedInUserDetails {
    getLoggedInUserDetails(limit: 1) {
      items {
        id
        userFilters {
          items {
            filterName
            filterDefinition
          }
        }
      }
    }
  }
`;

export const LIST_DIGITAL_LAB_INSTRUMENT_REASON = gql`
  query configurationByModelAndCreatedDate(
    $limit: Int
    $nextToken: String
    $filter: ModelIRConfigurableMasterFilterInput
  ) {
    configurationByModelAndCreatedDate(
      modelName: "IREDITREASON"
      sortDirection: DESC
      limit: $limit
      filter: $filter
      nextToken: $nextToken
    ) {
      nextToken
      items {
        configText
        id
      }
    }
  }
`;

export const LIST_DIGITAL_LAB_INSTRUMENT_REASON_UNICITY_CHECK = gql`
  query configurationByModelAndCreatedDate($filter: String) {
    configurationByModelAndCreatedDate(modelName: "IREDITREASON", filter: { configText: { eq: $filter } }) {
      items {
        configText
        id
      }
    }
  }
`;
export const LIST_DIGITAL_LAB_INSTRUMENT_DISPLAY_NAME_UNICITY_CHECK = gql`
  query getUserDisplay($displayName: String!, $userID: ID!) {
    getUserDisplay(displayName: $displayName, userID: $userID) {
      displayName
      id
      userID
      displayDefinition
    }
  }
`;

export const LIST_DIGITAL_LAB_INSTRUMENT_REASON_DROPDOWN = gql`
  query configurationByModelAndText {
    configurationByModelAndText(modelName: "IREDITREASON", sortDirection: DESC) {
      items {
        configText
        id
      }
      nextToken
    }
  }
`;

export const DATA_UPLOAD_URL = gql`
  query dataUploadUrl($targetModule: String, $fileName: String, $contentType: String) {
    dataUploadUrl(targetModule: $targetModule, fileName: $fileName, contentType: $contentType) {
      presignedUrl
      s3VersionId
    }
  }
`;

export const LIST_IR_UPLOAD_STATUS = gql`
  query uploadStatusByModelNameAndUploadDate(
    $nextToken: String
    $filter: ModelIRUploadStatusFilterInput
    $sortDirection: ModelSortDirection
    $modelName: String
  ) {
    uploadStatusByModelNameAndUploadDate(
      nextToken: $nextToken
      filter: $filter
      sortDirection: $sortDirection
      modelName: $modelName
    ) {
      items {
        email
        errorFileName
        errorFilePath
        fileName
        modelName
        s3VersionId
        status
        targetModule
        uploadDate
        userName {
          items {
            email
            familyName
            givenName
            name
            userId
          }
        }
      }
      nextToken
    }
  }
`;
export const LIST_IR_UPLOAD_STATUS_MASTER = gql`
  query listIRUploadStatus($limit: Int, $nextToken: String) {
    listIRUploadStatus(limit: $limit, nextToken: $nextToken) {
      items {
        s3VersionId
        status
        errorMessage
      }
      nextToken
    }
  }
`;
export const LIST_IR_DISPLAY_COLUMNS = gql`
  query listUserDisplays($userID: ID!) {
    listUserDisplays(userID: $userID) {
      items {
        displayDefinition
        displayName
        id
        userID
      }
    }
  }
`;

export const LIST_IR_SYSTEM_DISPLAY_COLUMNS = gql`
  query listSystemDisplays {
    listSystemDisplays {
      items {
        displayDefinition
        displayName
        id
      }
    }
  }
`;

export const LIST_IR_INBOXES = gql`
  query listIRInboxs($limit: Int, $nextToken: String, $filter: ModelIRInboxFilterInput) {
    listIRInboxs(limit: $limit, nextToken: $nextToken, filter: $filter) {
      items {
        id
        emailId
        fileName
        status
      }
    }
  }
`;

export const LIST_IR_INBOX_BY_USER_ID_AND_CREATE_DATE = gql`
  query listIRInboxByCreatedByIdAndCreatedDate(
    $createdById: ID
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
    $filter: ModelIRInboxFilterInput
  ) {
    listIRInboxByCreatedByIdAndCreatedDate(
      createdById: $createdById
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
      filter: $filter
    ) {
      items {
        id
        fileName
        emailId
        s3VersionId
        createdDate
        status
      }
      nextToken
    }
  }
`;

export const GET_EXPORTING_EQUIPMENT = gql`
  query getExportEquipmentData(
    $filterDefinition: String
    $selectedColumns: String
    $listQuery: String
    $dimensionUnit: String
  ) {
    getExportEquipmentData(
      filterDefinition: $filterDefinition
      selectedColumns: $selectedColumns
      listQuery: $listQuery
      dimensionUnit: $dimensionUnit
    ) {
      isSucceeded
    }
  }
`;

export const GET_WINSHUTTLE_EXPORT = gql`
  query getWinshuttleExport(
    $filterDefinition: String
    $selectedColumns: String
    $listQuery: String
    $dimensionUnit: String
    $type: WinshuttleExportTypes
  ) {
    getWinshuttleExport(
      filterDefinition: $filterDefinition
      selectedColumns: $selectedColumns
      listQuery: $listQuery
      dimensionUnit: $dimensionUnit
      type: $type
    ) {
      isSucceeded
    }
  }
`;

export const GET_BULK_EXPORTING_EQUIPMENT = gql`
  query getDownloadEquipmentForBulkChange($filterDefinition: String, $listQuery: String) {
    getDownloadEquipmentForBulkChange(filterDefinition: $filterDefinition, listQuery: $listQuery) {
      isSucceeded
    }
  }
`;

export const LIST_IR_ENTRY_BY_MANUFACTURE_MODEL_SNO = gql`
  query listIREntryByManufacturerModelAndSNo(
    $equipmentModel: String
    $manufacturer: String
    $linkedInstance: String
    $modelName: String
    $serialNumber: String
  ) {
    listIREntryByManufacturerModelAndSNo(
      manufacturerEquipmentModelSerialNumber: {
        eq: { equipmentModel: $equipmentModel, manufacturer: $manufacturer, serialNumber: $serialNumber }
      }
      modelName: $modelName
      filter: { linkedInstance: { eq: $linkedInstance } }
    ) {
      items {
        id
      }
    }
  }
`;

export const DOWNLOAD_TEMPLATE = gql`
  query downloadTemplate($targetModule: String) {
    downloadTemplate(targetModule: $targetModule)
  }
`;

export const LP_GET_LOGGED_USER_DETAILS_USER_FILTERS = gql`
  query getLoggedInUserDetails {
    getLoggedInUserDetails(limit: 1) {
      items {
        id
        name
        email
        userFilters {
          items {
            filterName
            filterDefinition
          }
        }
      }
    }
  }
`;

export const LIST_BULK_LINKED_INSTANCE = gql`
  query listIRBulkLinkedInstanceChanges($limit: Int, $nextToken: String) {
    listIRBulkLinkedInstanceChanges(limit: $limit, nextToken: $nextToken) {
      items {
        id
        failureCount
        emailId
        totalCount
        status
        successCount
        progressCount
      }
    }
  }
`;

export const LIST_BULK_EDIT_EQUIP_LIST = gql`
  query listIRBulkEdits($limit: Int, $nextToken: String) {
    listIRBulkEdits(limit: $limit, nextToken: $nextToken) {
      items {
        id
        failureCount
        emailId
        totalCount
        status
        successCount
        progressCount
      }
      nextToken
    }
  }
`;

export const LIST_IR_MASTER_DATA_MAPPINGS = gql`
  query listIRCategoryMappings($limit: Int, $nextToken: String) {
    listIRCategoryMappings(limit: $limit, nextToken: $nextToken) {
      nextToken
      items {
        equipmentCategory
        equipmentModel
        manufacturer
        variant
        weightInkg
        updatedAt
        modifiedById
        modelName
        isActive
        lengthInmm
        id
        heightInmm
        editReason
        editComment
        displayImage
        depthInmm
        createdById
        clearanceToFrontInmm
        clearanceRightInmm
        clearanceLeftInmm
        clearanceHeightInmm
        clearanceBackwardsInmm
        heatOutputInW
        pressureEquipment
        noiseIndBA
        noiseSource
        noiseSourceDetails
        positioningNote
        positioning
        vibrationSensitive
      }
    }
  }
`;

export const GET_IR_CATEGORY_MAPPING = gql`
  query getIRCategoryMapping(
    $equipmentCategory: String!
    $manufacturer: String!
    $equipmentModel: String!
    $variant: String!
    $modelName: String!
  ) {
    getIRCategoryMapping(
      equipmentCategory: $equipmentCategory
      manufacturer: $manufacturer
      equipmentModel: $equipmentModel
      variant: $variant
      modelName: $modelName
    ) {
      equipmentCategory
      equipmentModel
      manufacturer
      variant
      weightInkg
      updatedAt
      modifiedById
      modelName
      isActive
      lengthInmm
      id
      heightInmm
      editReason
      editComment
      displayImage
      depthInmm
      createdById
      clearanceToFrontInmm
      clearanceRightInmm
      clearanceLeftInmm
      clearanceHeightInmm
      clearanceBackwardsInmm
      heatOutputInW
      pressureEquipment
      noiseIndBA
      noiseSource
      noiseSourceDetails
      positioningNote
      positioning
      vibrationSensitive
    }
  }
`;

export const LIST_REPO_MASTER_DATA_AUDIT_BY_MASTER_DATA_REF_AND_MODIFIED_DATE = gql`
  query listRepoMasterDataAuditByMasterDataRefAndModifiedDate(
    $limit: Int
    $masterDataRef: String!
    $modelName: String!
    $nextToken: String
  ) {
    listRepoMasterDataAuditByMasterDataRefAndModifiedDate(
      limit: $limit
      masterDataRef: $masterDataRef
      filter: { masterDataModel: { eq: $modelName } }
      sortDirection: DESC
      nextToken: $nextToken
    ) {
      nextToken
      items {
        action
        changes
        editReason
        editComment
        masterDataModel
        masterDataRef
        modifiedByEmail
        modifiedByUserId
        modifiedDate
        newData
        oldData
        updatedAt
      }
    }
  }
`;

export const LIST_USER_DISPLAY_BY_USERID_AND_DISPLAYMODEL = gql`
  query listUserDisplayByUserIDAndDisplayModel($limit: Int, $userID: ID, $displayModel: String!, $nextToken: String) {
    listUserDisplayByUserIDAndDisplayModel(
      limit: $limit
      userID: $userID
      displayModel: { eq: $displayModel }
      nextToken: $nextToken
    ) {
      nextToken
      items {
        displayDefinition
        displayModel
        displayName
        id
        userID
      }
    }
  }
`;

export const LIST_USER_FILTER_BY_USERID_AND_FILTERMODEL = gql`
  query listUserFilterByUserIDAndFilterModel($limit: Int, $userID: ID, $filterModel: String!, $nextToken: String) {
    listUserFilterByUserIDAndFilterModel(
      limit: $limit
      userID: $userID
      filterModel: { eq: $filterModel }
      nextToken: $nextToken
    ) {
      nextToken
      items {
        filterDefinition
        filterModel
        filterName
        id
        userID
      }
    }
  }
`;
export const GET_EQUIPMENT_PROPOSED_CHANGES = gql`
  query getEquipmentProposedChanges($id: ID!) {
    getEquipmentProposedChanges(id: $id) {
      changes
      id
      inventoryId
      newData
      reviewStatus
      reviewers
      createdBy {
        items {
          email
          name
          userId
        }
      }
    }
  }
`;

export const GET_EQUIPMENT_PROPOSED_CHANGES_INVENTORY_ID = gql`
  query listEquipmentProposedChangesByInventoryIdAndReviewStatus($id: String, $limit: Int, $nextToken: String) {
    listEquipmentProposedChangesByInventoryIdAndReviewStatus(
      inventoryId: $id
      reviewStatus: { eq: "PENDING" }
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        changes
        id
        inventoryId
        newData
        reviewStatus
        reviewers
        createdBy {
          items {
            email
            name
            userId
          }
        }
      }
      nextToken
    }
  }
`;
export const VALIDATE_REVIEW_REQUIRED = gql`
  query validateReviewRequired($id: String, $query: String!, $updateRecord: String!) {
    validateReviewRequired(id: $id, query: $query, updateRecord: $updateRecord) {
      inventoryIds
      reviewRequired
    }
  }
`;

export const LIST_REVIEW_REQUIRED_TRACKING = gql`
  query listReviewRequiredTrackings($limit: Int, $nextToken: String) {
    listReviewRequiredTrackings(limit: $limit, nextToken: $nextToken) {
      items {
        id
        createdById
        reviewRequired
      }
      nextToken
    }
  }
`;

export const LIST_IR_CATEGORY_MAPPINGS_MINIMUM = gql`
  query listIRCategoryMappings($limit: Int, $nextToken: String) {
    listIRCategoryMappings(limit: $limit, nextToken: $nextToken, filter: { isActive: { eq: true } }) {
      nextToken
      items {
        equipmentModel
      }
    }
  }
`;

export const LIST_IR_CLUSTER_CATEGORY_MAPPINGS_MINIMUM = gql`
  query listIRClusterCategoryMappings($limit: Int, $nextToken: String) {
    listIRClusterCategoryMappings(limit: $limit, nextToken: $nextToken, filter: { isActive: { eq: true } }) {
      nextToken
      items {
        equipmentModel: clusterModel
      }
    }
  }
`;

export const GET_CHECKLISTS = gql`
  query getChecklists {
    listChecklists {
      items {
        status
        createdAt
        description
        models
        url
        sites
        groups
      }
    }
  }
`;

export const GET_CHECKLIST_TRAIL_LOG = gql`
  query getChecklist($createdAt: AWSTimestamp!) {
    getChecklist(input: { createdAt: $createdAt }) {
      status
      createdAt
      description
      models
      url
      sites
      groups
      auditTrail {
        date
        action
        user
      }
    }
  }
`;

export const GET_AFFECTED_IR_ENTRIES = gql`
  query getAffectedIrEntries(
    $equipmentCategory: String
    $manufacturer: String
    $equipmentModel: String
    $variant: String
    $nextToken: String
    $limit: Int
  ) {
    listIREntryByManufacturerModelAndSNo(
      modelName: "IREquipment"
      manufacturerEquipmentModelSerialNumber: {
        beginsWith: { manufacturer: $manufacturer, equipmentModel: $equipmentModel }
      }
      filter: { equipmentCategory: { eq: $equipmentCategory }, and: { variant: { eq: $variant } } }
      nextToken: $nextToken
      limit: $limit
    ) {
      items {
        id
      }
      nextToken
    }
  }
`;