import { useContext, useState, useEffect, useCallback } from "react";
import { useFormikContext } from "formik";
import { commonPropsForInputsWithoutValue } from "../helpers";
import { connect } from "react-redux";
import { compose, withApollo } from "react-apollo";
import { updateInstrumentDetail as updateInstrumentDetailAction } from "../redux/actions";
import DATA_MODEL_TABLE from "../../../utils/constants/dataModelTable";
import { StepperContext } from "../Context";
import { OwcInput, OwcIcon } from "@one/react";
import CommonOwcEditableDropDown from "../cluster/steps/CommonOwcEditableDropDown";
import { SOP_MANDATORY_CLP } from "../../../constants";
import { defaultInputStyle } from "../../../utils/helpers/text";
import { MainDescription } from "./MainDescription";
import { SerialNumberInput } from "../../../components/shared/SerialNumberInput";

const LinkedInstanceStep = ({
  isEditMode,
  updateInstrumentDetail,
  instrumentDetail,
  linkedInstanceList,
  isLinkedInstance
}) => {
  const formik = useFormikContext();
  const { secondaryIdMappingFields, setSecondaryIdMappingFields } = useContext(StepperContext);
  const [secondaryIDMappingFieldsIterator, setSecondaryIDMappingFieldsIterator] = useState(
    secondaryIdMappingFields || []
  );
  const [selectedLinkedInstance, setSelectedLinkedInstance] = useState(null);
  const [disableSecondayIdFields, setDisableSecondayIdFields] = useState(true);

  const onLinkedInstanceSelected = useCallback(
    async (linkedInstance) => {
      formik.resetForm();
      const selectLinkedInstance = linkedInstanceList.find(
        (item) => item.linkedInstance === linkedInstance?.linkedInstance
      );
      const selectedSecondaryIdMapping = selectLinkedInstance?.secondaryIdMapping;
      formik.setValues({
        ...formik.values,
        linkedInstance: linkedInstance
      });
      updateInstrumentDetail({
        ...instrumentDetail,
        secondaryIDMapping: selectLinkedInstance?.secondaryIdMapping
      });
      const selectedSecondaryIdMappingFromString = selectedSecondaryIdMapping?.split("; ");
      setSecondaryIdMappingFields(selectedSecondaryIdMappingFromString);

      if (
        selectLinkedInstance?.linkedInstance === "None" &&
        !selectedSecondaryIdMapping.includes("equipmentCategory")
      ) {
        // if linked instance = none we add category field to iterate over on the first screen (not a part of secondary ID)
        setSecondaryIDMappingFieldsIterator(["equipmentCategory", ...selectedSecondaryIdMappingFromString]);
      } else {
        setSecondaryIDMappingFieldsIterator(selectedSecondaryIdMappingFromString || []);
      }
      setSelectedLinkedInstance(() => linkedInstance?.linkedInstanceDisplay);
    },
    [formik, setSecondaryIdMappingFields, instrumentDetail, linkedInstanceList, updateInstrumentDetail]
  );

  useEffect(() => {
    if (formik?.values?.linkedInstance) {
      setSelectedLinkedInstance(() => `${formik?.values?.linkedInstance?.linkedInstanceDisplay}`);
    }
    if (!isEditMode && !formik?.values?.linkedInstance) {
      setSelectedLinkedInstance(() => `${formik?.values?.linkedInstance?.linkedInstanceDisplay}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik?.values?.linkedInstance]);

  /**
   * checking if element is disabled
   */
  useEffect(() => {
    const isDisabled = isEditMode ? false : isLinkedInstance;
    setDisableSecondayIdFields(isDisabled);
  }, [isEditMode, isLinkedInstance]);

  const assistiveText = "serialNumber" === DATA_MODEL_TABLE.serialNumber.key && (
    <span slot="assistive-text">{SOP_MANDATORY_CLP}</span>
  );

  return (
    <>
      {/* Linked Instance field */}
      <CommonOwcEditableDropDown
        key="linkedInstanceDisplay"
        labelValue={false}
        label={DATA_MODEL_TABLE.linkedInstance.value + ` *`}
        defaultLabel={DATA_MODEL_TABLE.linkedInstance.value + ` *`}
        propValue="linkedInstanceDisplay"
        style={defaultInputStyle}
        list={linkedInstanceList}
        selected={selectedLinkedInstance}
        onChange={(selectedValue) => {
          const selVal = linkedInstanceList?.find((value, index) => index === selectedValue);
          onLinkedInstanceSelected(selVal ?? null);
        }}
        required={true}
        disabled={isEditMode || disableSecondayIdFields}
        helperText={formik.errors[DATA_MODEL_TABLE.linkedInstance.key]}
      />
      {/* secondary Id field(s) + category for None */}

      {secondaryIDMappingFieldsIterator.includes(DATA_MODEL_TABLE?.manufacturer?.key) ? (
        <>
          <MainDescription hideVariant />
          <SerialNumberInput formik={formik} required assistivetext={assistiveText} />
        </>
      ) : (
        secondaryIDMappingFieldsIterator?.map((field) => {
          return (
            <div key={`secondaryIDIterator_${field}`} className="owcInputBox">
              <OwcInput
                data-testid={`text-field-${field}`}
                variant="filled"
                style={defaultInputStyle}
                label={`${DATA_MODEL_TABLE[field].value} *`}
                name={field}
                id={field}
                required
                disabled={
                  (instrumentDetail?.linkedInstanceRef?.linkedInstance !== "None" && isEditMode) ||
                  disableSecondayIdFields
                }
                value={formik.values?.[field]?.trim().split(/\s+/).join(" ")}
                onInputChange={formik.handleChange}
                onBlur={formik.handleBlur}
                {...commonPropsForInputsWithoutValue({
                  formik,
                  key: field,
                  dataTestIdKey: field,
                  dataTestIdForFormKey: "text-field"
                })}
              >
                {formik.errors[field] && formik.touched[field] === true && (
                  <>
                    <span slot="error-text">{formik.errors[field]}</span>
                  </>
                )}
                {formik.values[field] !== "" && formik.touched[field] === true && !disableSecondayIdFields && (
                  <OwcIcon
                    name="circle_clear_filled"
                    slot="suffix"
                    type="legacy"
                    onClick={() => formik.setFieldValue([field], "", true)}
                  />
                )}
                {field && field === DATA_MODEL_TABLE.serialNumber.key && (
                  <>
                    <span slot="assistive-text">{SOP_MANDATORY_CLP}</span>
                  </>
                )}
              </OwcInput>
            </div>
          );
        })
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  instrumentDetail: state.instruments?.instrumentDetail,
  linkedInstanceList: state.instruments?.linkedInstanceList
});

export default compose(
  connect(mapStateToProps, {
    updateInstrumentDetail: updateInstrumentDetailAction
  }),
  withApollo
)(LinkedInstanceStep);
